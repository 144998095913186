
#custom-responsi{
  display: flex;
  align-items: center;
  width: 100%;
}
#inputIdGLN{
  width: 100%;
}
#inputIdGTIN{
  width: 100%;
}
/* style={{display:'flex',alignItems:'center', width:'100%'}} */

.custom-width{
  width: 465px
}
.container_my_input_custom{
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  column-gap: 0.35578rem;
  padding: 0.1rem 1.7rem 0rem 1.7rem;
}
.custom-select1 {
  width: 45%;
  margin-right: 10px
}
/* style={{width:'45%', marginRight:'10px'}} */
.custom-border5{
 border: 1px solid #888b8d;
 max-width: 457px;
 border-radius: 4px;
width: 100%;
}
.custom-border5GLN{
  border: 1px solid #888b8d;
  border-radius: 4px;
  width: 100%;
}
.custom-border5GTIN{
  border: 1px solid #888b8d;
  border-radius: 4px;
  width: 100%;
}

.custom-button-especial {
  border: none;
  border-left: 1px solid #888b8d;
  width: 100%;
  max-height: 45px;
  padding: 13px;
  border-radius: 4px;
  background: #cd3c0d;
  color: #fff;
  text-align: center;
}
.custom-container-input{
  display: flex;
  flex: 1;
}
.buttonEspecialGLNcustom{
   max-width: 87px !important;
    width: 87px;
    padding-left: 22px !important;
    padding-right: 22px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;

}
.customSpan{
  font-size: 0.9375rem;
  color: #002c6c;
}
.customForm{
  border: transparent;
}

.custom-height-container-input{
  height: 100%;
}
@media (max-width: 640px) {
  .custom-container-input{
    flex-direction: column;
    margin-right: 10px;
    max-width: 80%;
  }
  .customSpan{
   margin:  0px 35px 0px 35px;
  }
  .custom-border5GLN{
    margin-bottom: 10px;
  }
  .custom-border5GTIN{
    margin-bottom: 10px;
  }
  .customForm{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-button-especial{
    padding: 0px;
    height:50px;
  }
  .custom-height-container-input{
    height: 50px;
  }
  #inputId{
    font-size: 11px;
  }
  .custom-select1{
    width: 80%;
  }
  .custom-border5{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #custom-responsi{
    flex-direction: column;
  }
}