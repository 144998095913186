.statusYellow{
  color: #002c6c;
}
.statusRed{
  color: #002c6c;
}
.statusGreen{
  color: #002c6c;
}
.accordionGLN{
  font-weight: 600 !important;
}
.glnfntw{
  font-weight: 600 !important;
}
.liGLN {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin:0px;
  height: auto;

  .expansive {
    transition: 0.4s;
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e5f0fc;
    ul {
      list-style: decimal;
      margin-left: none;
      padding-left:1rem;
      margin:0px 0px 0px 10px;
    }
  }
  tbody{
    background-color: #fff;

  }
  .child_li {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    // border-bottom: 1px solid #e5f0fc;
    padding-bottom: 1rem;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  // .child_li:hover {
  //   display: flex;
  //   cursor: pointer;
  //   justify-content: space-between;
  //   border-bottom: 1px solid #e5f0fc;
  //   padding-top: 15px;
  //   padding-bottom: 15px;
  // }
  .text {
    color: #002c6c;
    font-size: 1em;
    padding:3px 0;
    margin:0;    
    font-weight: 600 !important;
    font-family: "Gotham SSm A","Gotham SSm B",Verdana,sans-serif;
    line-height: 1.2;
  }
}

// .li:hover{
//   background-color: #e5f0fc;
// }



.marBtm{
  margin-top: 25px;
}
.cardGLN{
  position: relative;
    border: none;
    border-bottom: 1px solid #c4d7ed;
    border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}
.cardGLN:first-child {
  border-top: 1px solid #c4d7ed;
}
.card{
    &.card-h{
        height: 100%;
    }
    &.card-horizontal{
        .image-wrap{
          &.image-scale-down {
            object-fit: scale-down;
          }
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: .25rem 0 0 .25rem;
            object-fit: contain;
        }
    }
    &.card-no-border{
        border: none;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        box-shadow: none;
        position: relative;
        outline: 2px solid transparent;
        &:hover {
            box-shadow: none;
            -webkit-transform: none;
            transform:none;
            transition-duration: 0.2s;
            transition-timing-function: ease;
        }
        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }
        .card-body{
            padding:$spacer-4 0;
        }
    }
  border: 1px solid $color-ui-2;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  box-shadow: 0 0.125rem 0.188rem rgba(0, 44, 108, 0.2);
  position: relative;
  outline: 2px solid transparent;
  &:hover {
    box-shadow: 0 0.625rem 1.875rem rgba(0, 44, 108, 0.2);
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }
  &:active,
  &:focus {
    outline: 2px solid $color-blue;
    box-shadow: none;
  }
  .image-wrap {
    &.img-wrap-mobile{
      width: 100%;
      height: 100%
    }
    height: 170px;
    position: relative;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.25rem 0.25rem 0 0;
    object-fit: cover; 
  }
  
  .card-body {
    padding:$spacer-4;
    display: block;
    text-decoration: none;
    .card-title {
      color: $color-blue;
      margin-bottom: $spacer-1;
      font-weight: 500;
      line-height: $line-height-tight;
    }
    .card-text {
      font-size:$font-size-little;
      color: $color-ui-8;
      line-height: $line-height-tight;
    }
  }
  &.card-icon {
    .card-body {
      .icon-card {
        width: 4rem;
        float: left;
        padding-right: $spacer-3;
        svg {
          fill: $color-orange;
        }
      }
    }
  }
}
@media (max-width: 736px) {
  .card {
    &.card-h {
      height: auto;
      .card-body {
        .card-text {
          display: none;
        }
      }
    }
    &.card-horizontal{
      &.card-horizontal-mobile{
        .image-wrap{
          height: 170px;
          position: relative;
        }
        .card-body {
          .card-text {
            display: block;
          }
        }
      }
      .card-body {
        .card-text {
          display: none;
        }
      }
    }
    &:hover {
      box-shadow: 0 0.625rem 1.875rem rgba(0, 44, 108, 0.2);
      -webkit-transform: none;
      transform: none;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }

    .image-wrap {
      &.img-wrap-mobile{
        width: 100%;
        height: 170px;
        border-radius: 0;
        position: relative;
      }
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
}
