.custom-font-size {
    font-size: 20px;
}
.bannerTotCustom{
        width: 100%;
        max-width: 1100px;
        background: white;
        padding-top: 35px;
        padding-left: 35px;
        padding-right: 35px;
        padding-bottom: 35px;
}
.boxApiActiveCustom{
    background: #002c6c;
    border: 1px solid #c4d7ed;
    border-radius: 4px;
    padding: 1.125rem;
    height: 100%;
    position: relative;
    cursor: pointer !important;
    color: white;
    /* max-width: 241.5px; */
    max-height: 192px;
    width: 241.5px;
    height: 192px;
    clip-path: polygon(0 0, 0 200%, 100% 200%, 100% 25%, 75% 0)

}
.boxApiActiveCustom .boxIcon {
    filter: invert(82%) sepia(57%) saturate(840%) hue-rotate(325deg) brightness(94%) contrast(111%);
}
.boxApiActiveCustom .boxTitle{
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
        font-weight: 400;
        font-size: 20px;
        color: #fff;
}
.boxApiActiveCustom::before {
    background-image: url('../../../public/download.svg');
    position: absolute;
    bottom: -25px;
    left: -10px;
    right: 0;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    content: " ";
}
.rowBoxApiCustoum{
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 16px;
      padding-right: 15px;
      padding-left: 6px;
}
.boxApiCustom{
    background: #f0f5fa;
    border: 1px solid #c4d7ed;
    border-radius: 4px;
    padding: 1.125rem;
    height: 100%;
    position: relative;
    /* width: 100%; */
    max-height: 192px;
    min-height: 95px;
    cursor: pointer;
    color: #002c6c !important;
    width: 241.5px;
    height: 192px;
}
@media (max-width: 640px) {
    .custom-container-input{
      flex-direction: column;
      margin-right: 10px;
      width: 80%;
    }
    .custom-font-size{
        font-size: 15px;
        
    }
    .rowBoxApiCustoum{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
        padding-right: 9px;
    }
    .boxApiActiveCustom{
        width: 100%;
        max-height: 97px;
    }
    .boxApiCustom {
        max-height: 0px;
        min-height: 95px;
        width: 100%;
    }
    .boxApiActiveCustom .boxTitle{
        font-size: 17px;
    }
    .custom-button-especial{
      padding: 0px;
      height:50px;
    }
    .custom-height-container-input{
      height: 50px;
    }
    #inputId{
      font-size: 15px;
    }
    .custom-select1{
      width: 80%;
    }
    .custom-border5{
      margin-top: 10px;
      margin-bottom: 10px;
    }
    #custom-responsi{
      flex-direction: column;
    }
  }

  @media (max-width: 400px) {
    #inputId {
        font-size: 11px;
    }
    .rowBoxApiCustoum{

      padding-left: 11px;
      padding-right: 5px;
    
    }
}
