.breadcrumb{
    background-color: transparent;
    padding: 0;
    font-size:$font-size-little-one;
    color: $color-link-accessible;
    padding:$spacer-2 0;
    margin-bottom: 0;
    a {
        color: $color-link-accessible;
        transition: color 0.1s ease;
        outline: 2px solid transparent;
        &:hover {
        color: $color-blue;
        }

        &:active {
        color: $color-blue-dark-2;
        }

        &:focus {
        outline-color: $color-blue;
        }
    }
    .node,
    .node-root,
    .node-separate{
        margin-right: .75rem;
    }
    .node-current{
        color:$color-ui-6;
        margin-right:0;
    }
}
@media (max-width: 736px) {
    
}