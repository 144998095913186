.structured-list{
    list-style: none;
    .item-structured-list{
        margin-bottom: 0;
        .link-list{
            padding-top:0.75rem;
            padding-bottom:0.75rem;
            padding-left: 1.25rem;
            border-bottom: 1px solid $color-ui-3;
            display: inline-block;
            .title-item-structured-list{
                width: 100%;
                display: inline-block;
                font-size:$font-size-little;
                font-weight: 500;
                color: $color-orange-accessible;
                line-height: $line-height-tight;
                margin-bottom: 0.25rem;
            }
            .description-item-structured-list{
                width: 100%;
                display: inline-block;
                font-size: 0.75rem;
                font-weight: 400;
                color: $color-ui-7;
                line-height: 1.333rem;
            }
        }
        &:hover{
            background-color: $color-ui-1;
        }
    }
}
@media (max-width: 736px) {
    .structured-list{
        margin-top: $spacer-4;
        list-style: none;
        .item-structured-list{
            margin-bottom: 0.75rem;
            .link-list{
                padding-top:0.75rem;
                padding-bottom:0.75rem;
                padding-left: 0.75rem;
                border-bottom:none;
                display: inline-block;
                .description-item-structured-list{
                    font-size:$font-size-little;
                }
            }
            &:hover{
                background-color: transparent;
            }
           
        }
    }
}
