.accordion {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    font-size: $font-5;
    font-weight: 500;
    color: $color-blue;
    line-height: 1.389;
    span {
      margin-right: 1.5rem;
    }
    svg {
      position: absolute;
      right: 15px;
    }
  }
  > .card {
    border: 0;
    border-radius: 0;
    border-top: 1px solid $color-ui-3;
    box-shadow: none;
    margin-bottom: 0;
    position: relative;
    &:active,
    &:focus {
      margin-bottom: 2px !important;
    }
    .card-header {
      padding: 0.75rem 1.5rem;
      background: none;
      border: 0;
    }
    .card-body {
      padding: 1.5rem 4.25rem 1.5rem 1.5rem;
    }
    .titulo-pergunta-faq{
      &.collapsed{
        &::after{
          content: '';
          width:4px;
          height: 100%;
          background-color: transparent;
          position: absolute;
          top:0;
          left: 0;
        }
      }
      &::after{
        content: '';
        width:4px;
        height: 100%;
        background-color: $color-orange;
        position: absolute;
        top:0;
        left: 0;
        border-radius: .24rem;
      }
    }
  }
  > .bLaranja {
    border-left: 4px solid #f26334 !important;
  }
  .card:last-of-type {
    border-bottom: 1px solid $color-ui-3;
  }
  .card:hover {
    transform: unset;
    background: $color-ui-2;
    margin-bottom: 0px;
  } 
}
