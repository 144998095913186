
.form-group{
    .erroForm{
        font-size: .75rem;
        font-weight: 500;
        color: $color-danger;
    }
}
#login{
  text-transform: lowercase;
}
input[type='password'],
input[type='text'],
input[type='file'],
.form-control {
  height: calc(2rem + 0.75rem + 1px);
  padding-left:$spacer-3;
  border: 1px solid $color-ui-3;
  font-size: 0.938rem;
  font-weight: 400;
  &::placeholder {
    color: $color-ui-6;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color-ui-6; 
    font-size: 0.938rem;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color-ui-6;
    font-size: 0.938rem;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color-ui-6;
    font-size: 0.938rem;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color-ui-6;
    font-size: 0.938rem;
  }
  &:placeholder-shown {
    color: $color-ui-6;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 0.125rem rgba(0, 44, 108, 1);
    outline: $color-blue;
  }
  &:hover {
    border-color: $color-ui-3;
  }
}
.form-label {
  font-size:$font-size-little-one;
  color: $color-ui-7;
}
.custom-file-label {
  border-color: $color-ui-3;
  font-size:$font-size-little-one;
  height: calc(2rem + 0.75rem + 1px);
  line-height: 2rem;
  padding-left: 1.125rem;

  &:after {
    background-color: $color-orange-accessible;
    border-color: $color-orange-accessible;
    color: $color-white;
    content: "Escolher arquivo";
    height: calc(2rem + 0.75rem - 1px);
    line-height: 2rem;
  }
}

.form-check-label {
  color: $color-ui-8;
  font-size: 0.938rem;
}

//css checkbox custom bootstrap

.custom-control {
  .custom-control-input {
    width: 1.313rem;
    height: 1.313rem;
    &:checked ~ .custom-control-label::before {
      color: $color-white;
      border-color: $color-ui-8;
      background-color: $color-ui-8;
    }
    &:not(:disabled):active ~ .custom-control-label::before {
      background-color: transparent;
      border-color: $color-ui-8;
      box-shadow: none;
    }
    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: $color-ui-8;
      outline: 2px solid $color-blue;
    }
    &:focus ~ .custom-control-label::before {
      box-shadow: none;
      outline: 2px solid $color-blue;
    }
  }
  .custom-control-label {
    font-size: 0.938rem;
    color: $color-ui-8;
    padding-left: 0.5rem;
    cursor: pointer;
    &::before,
    &::after {
      width: 1.313rem;
      height: 1.313rem;
      top: 0;
      border-color: $color-ui-8;
    }
    &.is-invalid{
        background-repeat: no-repeat;
        padding-right: calc(1.5em + .75rem);
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
        border-color: $color-danger!important;
        background-image: url("data:image/svg+xml,%3Csvg role='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ba1b23' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3E%3C/path%3E%3C/svg%3E")!important;
    }
  }

  &.custom-radio {
    .custom-control-input:checked~.custom-control-label::before {
      border-color: $color-ui-8;
      background-color: $color-white;
    }
    .custom-control-input:checked~.custom-control-label:after {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23262626'/%3E%3C/svg%3E");
      background-size: 11px;
    }
  }
}

.was-validated input[type=password], 
.was-validated input[type=text], 
.was-validated input[type=file],
.was-validated select,
.was-validated .custom-select,
.was-validated .form-control{

    background-repeat: no-repeat;
    padding-right: calc(1.5em + .75rem);
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);

    &:valid{
        padding-left: 3px;
        border: 1px solid $color-ui-3;
        font-size:$font-size-little-one;
        font-weight: 400;
        outline: 2px solid transparent;
        padding-top: 1px;
        padding-bottom: 1px;
        box-shadow: none;
        outline: none;
        background-image: none;
    }

    &:invalid{
        border-color: $color-danger!important;
        background-image: url("data:image/svg+xml,%3Csvg role='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ba1b23' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'%3E%3C/path%3E%3C/svg%3E")!important;
    }

    &.is-valid-show{
        border-color: $color-forest-accessible;
        background-image: url("data:image/svg+xml,%3Csvg role='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23008037' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'%3E%3C/path%3E%3C/svg%3E");
    }

}

.was-validated select,
.was-validated .custom-select{
    background-position: right calc(.375em + 0.8rem) center !important;
}

.form-group label{
  font-size:$font-size-little-one;
}

.erroForm {
    color:$color-danger;
    font-size: 0.7em;
    font-weight: 600;
}