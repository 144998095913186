ul.nav{
  border-bottom-color: lightgray;
  border-bottom-style: solid;
  border-bottom-width: medium;
  width: 100%;
}
ul.nav li:hover{
  background: #e5f0fc;
  border-radius: 0.25rem;
}
.propertyvalueCustom {
  font-size: 0.75rem !important;
  color: rgb(0, 44, 108);
}

ul.nav li {
  cursor: pointer;
  margin-bottom: -2px;
  padding-bottom: 10px;
  color: #262626;
  display: block;
  position: relative;
  font-weight: 500;
  font-style: normal;
  font-size: 0.875rem;
  width: 250px;
  text-align: center;
}
ul.nav li.active {
  color: #002c6c;
  font-weight: bold;
  position: relative;
  display: block;
  border-bottom-color: #cd3c0d;
  border-bottom-style: solid;
  border-bottom-width: medium;
  background: #f0f5fa;
  border-radius: 0.25rem;
}
/* .outlet{
  margin-left: 15px;
  margin-right: 15px;
} */

/* Estilos para o overlay do modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 38, 38, 0.8);
  z-index: 1000;
}

/* Estilos para o conteúdo do modal */
.modal-content {
  width: 800px;
  height: 230px;
  margin: 10% auto 0 auto;
  z-index: 1000;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 10px;
}

@media (max-width: 500px){
  ul.nav li {
      width: 100px !important;
  }
}
.custom-flex-layout {
  display: flex;
  font-size: 18px;
}

.custom-margin-valid{
  text-align: left;
  padding-top: 18px;
}

.custom-border2{
    order-width: 3px;
    border-style: solid;
    border-top: 0px;
    border-bottom: 0px;
    border-left: 0px;
    border-color: rgb(0, 44, 108);
}
.custom-col-6 {
  width: 50%; 
}

/* Aplica o layout em coluna quando a tela for menor ou igual a 640px */
@media (max-width: 640px) {
  .custom-flex-layout {
    flex-direction: column;
  }
  .modal-content {
    height: 490px;
  }
  .custom-border {
    border-style: none;
  }
  .modal-content{
    max-width: 310px;
  }
  .custom-margin-valid{
    margin-bottom: 0px;
  }
  .custom-col-6 {
    width: 100%;
    margin-bottom: 12px;
  }
}
