#s4-titlerow {
  background-color: $color-white;
  border-bottom: 1px solid $color-ui-2;
  display: block;
  height: 120px;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 99;
  position: fixed;
  width: 100%;
  .global-language {
    color: $color-ui-7;
    font-family: 'Gotham SSm A', 'Gotham SSm B', Verdana, sans-serif;
    font-size: 12px;
    font-weight: 500;
  }

  #titleAreaBox {
    margin: auto;
    // padding: 1.5rem 0.9375rem 0;

    #siteIcon {
      line-height: normal;
      margin: 0;
      width: auto;

      .ms-siteicon-img {
        max-width: 95px;
        max-height: 61px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
      }
    }

    .links-top {
      list-style: none;
      padding: 0;
      font-family: 'Gotham SSm A', 'Gotham SSm B', Verdana, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      text-align: right;

      li {
        display: inline-block;
        padding-left: 8px;
        margin: 0;

        a {
          display: block;
          line-height: 14px;
          text-decoration: underline;
          &:visited {
            color: $color-link-accessible;
            transition: color 0.1s ease;
          }
          &:hover {
            color: $color-blue;
          }

          &:active {
            color: $color-blue-dark-2;
          }

          &:focus {
            outline-color: $color-blue;
          }
        }
      }
    }

    .search-box {
      position: relative;
      min-width: 32px;
      width: 0%;
      height: 40px;
      overflow: hidden;

      -webkit-transition: width 0.3s;
      -moz-transition: width 0.3s;
      -ms-transition: width 0.3s;
      -o-transition: width 0.3s;
      transition: width 0.3s;

      .search {
        color: $color-ui-6;
        display: flex;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0.75rem;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
          text-decoration: none;
        }
      }

      .searchbox-input {
        background: $color-white;
        border: 0;
        color: $color-ui-6;
        font-size: 0.875rem;
        top: 0;
        right: 0;
        outline: 0;
        width: 100%;
        height: 40px;
        margin: 0;
        padding: 0.625rem 1.25rem 0.625rem 2.5rem;

        &::-webkit-input-placeholder,
        &:-moz-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder {
          color: $color-ui-6;
        }
      }

      &.searchbox-open {
        width: 250px;
        .searchbox-input {
          border: 1px solid $color-ui-3;
          border-radius: 0.25rem;
        }
      }
    }

    .btns-header {
      display: flex;
      justify-content: flex-end;
      text-align: right;

      a {
        margin-left: 15px;
      }
    }

    .ms-breadcrumb-box {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  #s4-titlerow {
    box-shadow: none;
    border: 0;
  }
}
