.rowGlnAddresmany{
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}
.accordionGLN{
  background-color: #f0f5fa;
}
.aboutPrincipal{
  padding-top: 130px;
}
.linkBtnPrimary{
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #cd3c0d;
  border-color: #cd3c0d;
}
.secondaryRowsBorder{
  // background-color: #fff;
  // border: none;
  // border-bottom: 1px solid #c4d7ed;
  list-style-type: none;
}
.rowsBorder{
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #c4d7ed;
}
.lastItemRow{
  margin-bottom: -5px;
}
.linkBtnSecondary{
  color: #cd3c0d;
  background-color: #ffffff;
  border-color: #cd3c0d;
}
.banner2{
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 100%);
}
@media (max-width: 767px){
  .aboutPrincipal{
    padding-top: 175px;
  }
}
@media (max-width: 767px){
  .textCenterMin687{
    text-align: center;
  }
}
.datepicker {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 3px;
    height: 40px;
    width: 220px;
    padding: 0 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #a1a1a1;
}

.tableUser {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.tableUser .tabNavigation {
    border-bottom: solid 4px rgb(234, 234, 234);
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 30px;
}

.tab {
  &.active{
    border-bottom: solid 4px #F26334;
    transition: 0.4s;
  }
}

.tableUser .tabNavigation .active {
    border-bottom: solid 4px #cd3c0d;
}

.tableUser .tabNavigation .tab {
    display: flex;
    padding: 8px 15px;
    position: relative;
    margin-bottom: -4px;
    cursor: pointer;
}

// modal de confirmação 

.formUserHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
}

.bgalert {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgb(0, 0, 0, 0.7);
    z-index: 8;
  
    .alert {
      width: 350px;
      height: max-content;
      background-color: white;
      position: fixed;
      z-index: 9;
      left: 50%;
      top: 50%;
      margin-left: -175px;
      margin-top: -75px;
      border: solid 1px rgb(234, 234, 234);
      border-radius: 5px;
  
      .containeralert {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
  
        .contentalert {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          padding: 10px;
        }
  
        .footeralert {
          display: flex;
          flex-direction: row;
          gap: 8px;
          justify-content: flex-end;
          height: max-content;
          padding: 10px;
        }
  
        button {
          cursor: pointer;
        }
      }
  
  
    }
  }
  
  
  .faqTbody {
    border-spacing: 0 0.5rem;
    background-color: transparent;
  
    td {
      border-bottom: 1px solid rgb(129, 120, 120, 0.3);
      opacity: 1;
      max-height: 6.5rem;
      height: auto;
      max-width: 30rem;
      width: auto;
      text-align: center;
      font-size: 14px;
    }
  
    tr th {
      text-align: center;
      border-bottom: 1px solid rgb(129, 120, 120, 0.3);
  
      font-size: 15px;
      white-space: nowrap;
      max-width: 30rem;
      width: 100%;
  
      font-weight: 500;
    }
  }
  .table {
  td:nth-child(4) {
    text-align: left;
  }
  tr th:nth-child(4) {
    text-align: left;
  }
}
  
  .faqThead {
    height: 2.3rem;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    color: #002B6B;
  }

/*Modal de contestação*/
.boxModal {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    .iconClose {
      position: absolute;
      top: -6px;
      right: 0;
    }
    .containerModal {
      margin-top: 0rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      padding-bottom: 1.5rem;
      position: relative;
      .childButton {
        display: flex;
        justify-content: flex-end;
        margin-right: 7px;
        .contestation {
          all: unset;
          background: #cd3c0d;
          color: #ffffff;
  
          font-size: 18px;
          padding: 10px 12px;
          text-align: center;
          cursor: pointer;
  
          border-radius: 4px;
        }
      }
      .iGtin {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin: 0 12px;
        input{
          color: #a6b0bb !important;
          color:#0c48a1
        }
      }
  
      input, select, textarea {
        padding: 6px 11px;
        border-radius: 4px;
        width: 90%;
        font-size: 16px;
        color: #252525;
        border: none;
        border: 1px solid #c4d7ed;
        outline: none;
      }
  
      .children {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        .c1 {
          display: flex;
  
          flex-direction: column;
          .addItem {
            width: 100%;
            .buttonAdd {
              all: unset;
              background: #ffffff;
              color: #cd3c0d;
              border: 2px solid #cd3c0d;
              font-size: 14px;
              padding: 10px 12px;
              margin-top:15px;
              text-align: center;
              cursor: pointer;
              border-radius: 4px;
            }
          }
          .m {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 5px;
            margin: 10px 0px;
            padding: 15px 0px 15px 10px;
            row-gap: 10px;
  
            .remove {
              text-decoration: underline;
              color: rgb(0, 41, 95);
              text-align: right;
              margin-right: 00px;
              margin-bottom: -20px;
              margin-top: -8px;
              font-size: 25px;
              padding:0px 10px 10px 10px;
            }
          }
  
          .m:hover{
            background-color: rgb(240, 240, 240);
            margin: 10px 0;
          }
        }
      }
    }
  }


/*Busca Simples*/
.little_text {
  font-size: 0.9375rem;
  color: #002c6c;
}

.search_bar_box {
  all: unset;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #888b8d;
  border-radius: 4px;
  &.error {
    border-color: red;
  }
  label {
    width: 100%;
  }
  button {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
  }
}

.container_my_input {
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: space-between;
  align-items: center;
  border-radius: 4px;
  column-gap: 0.35578rem;
  padding: 0.6rem 1.7rem 0rem 1.7rem;
  .especial_input {
    all: unset;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
}

.buttonEspecial {
  all: unset;
  border: none;
  border-left: 1px solid #888b8d;

  width: 100%;
  height: 100%;
  padding: 0.7rem 1.7rem;
  border-radius: 4px 0 0 4px;
  background: #cd3c0d;
  color: #fff;
}


/*Busca múltipla*/


.check {
  display: flex;
  flex-direction: row;
  column-gap: 0.4rem;
  align-items: center;
}

.box a{
  color:#00799E;
}

.box a:hover{
  color:#002C6C;
  text-decoration: underline;
}

.containerDetail
{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: white;
    font-size: 0.9rem;
    overflow: hidden;
    color: #262626;

    .warningarea
    {
      display: flex;
      width: auto;
      margin:30px 27px 0 25px;

    }

    //sobrescreve regra do datatable para as miniaturas dos produtos
    .eNWVNP {
      max-width: 60px !important;
      min-width: 60px !important;
      overflow: hidden;
    }

    .eNWVNP img{
      max-width: 60px !important;
      max-height: 55px !important;
      overflow: hidden;
    }


    .headerinfos
    {
      display: flex;
      width: 93%;
      align-items: center;
      margin:36px 40px 25px 40px;
      flex-direction: row;
      justify-content: space-between;

      .description_headerinfos
      {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #002C6C;

        h2{
          padding: 0;
          margin: 0 0 7px 0;
          font-size: 26px;
        }

        p{
          font-size: 15px;
          padding: 0;
          margin: 0;
        }
      }

      .buttons_headerinfos
      {
        display: flex;
        gap: 28px;
        flex-direction: row;
        justify-content: flex-end;

        button
        {
          background-color: #F0F5FA !important;
          border: #C4D7ED !important;
          color: #002C6C !important;
          border-radius: 3px !important;
          width: 100px;
          height: 48px;
          font-size: 15px;
          font-weight: 200 !important;
        }
      }
    }

    .headerSearch
    {
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;
        border-bottom: 1px solid #d8d8d8;
        width: 98%;
        padding: 25px 20px 15px 20px;
        align-items: flex-start;
        justify-items: center;

        .inputSearch{
            display: flex;
            width: 300px;
            margin: 0 45px 0 20px;

            .searchbox {
              all: unset;
              width: 100%;
              padding: 0;
              margin: 0;
              box-sizing: border-box;
              height: auto;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              height: 34px;
              align-items: center;
              background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
              border: 1px solid rgba(196, 215, 237, 1);
              border-radius: 4px;
              overflow: hidden;
              label {
                width: 100%;
              }
              button {
                width: 15%;
                display: flex;
                font-size: 30px;
                justify-content: center;
                align-items: center;
                background-color: white;
                color: rgba(38, 38, 38, 1);
                height: 33px;
                border: none;
              }
            }
        }

        .completeProducts{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            justify-items: center;
            padding:10px 0 0 0;

            input{
              transform: scale(1.7);
              margin-right: 10px;
            }
        }


    }

    .headerList{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        justify-items: center;
        padding: 20px 10px 15px 10px;
        border-bottom: 1px solid #d8d8d8;
        color: rgb(0, 44, 108);
        

        .gtin{
            width: 185px;
            justify-content: center !important;
            align-items: center !important;
            justify-items: center !important;
            text-align: center;  
            margin:0 65px 0 0; 
        }

        .productDescription{
            width: 450px;
            display: flex;
        }

        .pagination{
            display: flex;
        }
    }

    .rowList{

        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #d8d8d8;

        .imageList, .productIcon
        {
            width: 60px;
            height: 60px;
            justify-content: center;
            align-items: center;
            justify-items: center;
            display: flex;
            margin-left: 5px;
        }

        .imageList img{
            max-height: 50px;
            margin-right: 120px;
        }

        .productIcon img{
            height: 30px;
        }

        .gtinCode{
            display: flex;
            width: 175px;
            padding-left:20px
        }

        .productDescription
        {
            display:flex;
            width: 700px;
        }
        &:hover{
          background-color: rgb(245, 245, 245);
          transition: 0.4s;
        }

        
    }

    
    .error
    {
        background-color: #FFDFDF;
        color: #BA1B23 !important;
    }
    .error:hover
    {
        background-color: #BA1B23 !important;
        color: #FFDFDF;
    }

    .footerList
    {
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        align-items: center;
        height: 65px;
    }


}

.inputSearch{
  display: flex;
  width: 300px;
  margin: 0 45px 0 20px;

  .searchbox {
    all: unset;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 34px;
    align-items: center;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 1px solid rgba(196, 215, 237, 1);
    border-radius: 4px;
    overflow: hidden;
    label {
      width: 100%;
    }
    button {
      width: 15%;
      display: flex;
      font-size: 30px;
      justify-content: center;
      align-items: center;
      background-color: white;
      color: rgba(38, 38, 38, 1);
      height: 33px;
      border: none;
    }
  }
}

.completeProducts{
display: flex;
justify-content: flex-start;
align-items: center;
justify-items: center;
padding: 3px 0 0 0;

input{
  transform: scale(1.7);
  margin-right: 10px;
}
}
.buttonRowSearcMany{
  display: flex;
    flex-direction: row;
    gap: 20px;
}
.imageList img{
  max-height: 50px;
  margin-right: 46px;
  overflow: hidden;
}

.chooseFile{
  padding: 1rem;
  color: #002C6C;
  background: #C4D7ED;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  width: 180px;
  margin-bottom: 0px !important;
}

.btnSearchMultiple{
  
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2rem;
}

.buttons_headerinfos
{
  display: flex;
  gap: 28px;
  flex-direction: row;
  justify-content: flex-end;
  width:100%;

  button
  {
    background-color: #F0F5FA !important;
    border: #C4D7ED !important;
    color: #002C6C !important;
    border-radius: 3px !important;
    //width: 100px;
    height: 48px;
    font-size: 15px;
    font-weight: 200 !important;
  }
}

// Login

.boxLogin {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 2rem;
}

.contentLogin {
// padding-top: 3rem;
}

// Forget

.boxForget {
  max-width: 29rem;
  width: 100%;
  padding: .1rem 2rem 2rem 2rem;
  border-radius: 4px;
  grid-template-columns: auto;
  //border-radius: 16px;
  background-color: #fff;
}

// Define Password

.link {
  color: "#00799E";
  text-decoration: "underline"
}

.access {
  width: 100%;
  min-height: 700px;
  display: flex;
  justify-content: flex-end;
  background: url(../public/vbg_home.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 7px;


  // height: auto;
  .c_access {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;

    //padding: 2rem;
    .login_stage {
      display: flex;
      //flex-direction: column;
      justify-content: flex-end;

      .content_c {
        h2 {
          line-height: 2;
          color: #ffffff;
          font-size: 26px;
        }

        p {
          line-height: 2;
          color: #ffffff;
          font-size: 18px;
        }

        .box {
          nav {
            display: flex;
            flex-direction: column;

            a {
              margin-top: 0.4375rem;
              line-height: 1.2;
            }
          }

          .alternative_login {
            display: flex;
            height: 10px;
            column-gap: 10px;
            justify-content: flex-start;
            align-items: center;
          }

          .separator {
            font-size: 0.8rem;
            color: #a8a8b3;
            margin: 32px 0;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              flex: 1;
              height: 1px;
              background: #a8a8b3;
            }

            &::after {
              content: "";
              flex: 1;
              height: 1px;
              background: #a8a8b3;
            }
          }
        }
      }
    }
  }
}

span.erro {
  font-size: 12px;
  color: #BA1B23;
  margin-top: 7px;
}

.errorinput {
  border: solid 1px #96242F !important;
}

// Accordion

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.li {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin:0px;
  height: auto;

  .expansive {
    transition: 0.4s;
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e5f0fc;
    ul {
      list-style: decimal;
      margin-left: none;
      padding-left:1rem;
      margin:0px 0px 0px 10px;
    }
  }
  .child_li {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    // border-bottom: 1px solid #e5f0fc;
    padding-bottom: 1rem;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .child_li:hover {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    border-bottom: 1px solid #e5f0fc;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .text {
    color: #002c6c;
    font-size: 1em;
    padding:3px 0;
    margin:0;
  }
}

.li:hover{
  background-color: #e5f0fc;
}

.leftborder
{
  border-left:solid 3px rgb(226, 88, 52);
}
.headerborderbottom
{

  border-bottom: 1px solid #e5f0fc !important;
  z-index:0 !important;
}

.nav-mobile {
  z-index: 0 !important;
}


/*Menu backend*/
.static {
  .textMenu {
      display: flex;
      font-size: 1.125rem;
      padding-left: 1.4rem;
      text-decoration: none;
      color: #002C6C;
      margin-bottom: .6rem;

      &:hover {
          text-decoration: none;
      }

  }

  .menuNavLink {
      display: flex;
      font-size: 0.9375rem;
      text-decoration: none;
      color: #00799E;
      padding-left: 1.4rem;
      margin-bottom: .5rem;

      &:hover {
          color: #002C6C;
          font-size: 1.125rem;
      }
      &.active {
          color: #002C6C;
          font-size: 1.125rem;
      }
  }
}

.menuhorizontal
{
  font-size: 13px;

  &:hover {
      color: #002C6C;
  }
  &.active {
      color: #002C6C;
  }
}

.static .selected {
  max-height: 20.9375rem;
  height: 100%;
}
.navbar.navbar-default.navbar-expand-lg {
  max-width: 15.9375rem;
  width: 100%;
  min-height: 15rem;
}


/*Product Detail*/
.printdata
{
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 0 0 2rem 0;

  button{
    all: unset;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #C4D7ED;
    color: #002C6C;
    border-radius: 4px;
    font-size: 15px;
    cursor:pointer;
  }
}

.color-ui-6 {
  color: #696d73;
}

.body-sm {
    font-size: 12px !important;
}



.image{
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: stretch;
}


.property{
  font-size: 0.75rem !important;
  color: rgb(105, 109, 115);
  width:250px !important;
}

.propertyCompany{
  font-size: 0.75rem !important;
  color: rgb(105, 109, 115);
  max-width:370px !important;
  width:370px;
}
@media (max-width: 500px){
  .propertyCompany{
    font-size: 0.75rem !important;
    color: rgb(105, 109, 115);
    max-width:130px !important;
    width:130px;
  }
}


.infogepirerror{
  font-size: 0.75rem !important;
  color: rgb(105, 109, 115);
  display:table;
}


.propertyvalue
{
  font-size: 0.75rem !important;
  color: rgb(0, 44, 108);
  justify-items: center;

}

.detailBox{
  background-color: #ffffff;
}

.titleProductDetail{
  margin-top: 24px !important;
  margin-bottom: 3rem !important;
  font-weight: 700;
}

.titleCompanyDetail{
  margin-top: 24px !important;
  margin-bottom: 3rem !important;
  font-weight: 700;
}

.titleProductDetailGln{
  margin-bottom: 0rem !important;
  font-weight: 700;
}
.titleProductDetailGlnMultipleSub{
  font-weight: bolder;
  margin-top: 10px !important;
}
.titleProductDetailGlnMultiple{
  font-weight: bolder;
  margin-top: 10px !important;
}
.valid {
  padding: 4px 12px;
  border-radius: 14px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #026b30;
  border: 1px solid #026b30;
  background-color: #ccf1dc;
}
.invalid {
  padding: 4px 12px;
  border-radius: 14px;
  color: #ba1b23;
  font-size: 0.75rem;
  font-weight: 500;
  border: 1px solid #ba1b23;
  background-color: #ffdfdf;
}

.faq{
  font-size: 12px;
  a{
    text-decoration: none;
    color:#002c6c;
  }
  a:hover{
    color:#0e56c1
  }
}

.table th, .table td
{
  padding: 0.5rem 0.3rem !important;
}

.flag{
  border: solid 1px #F0F5FA;
}

.lineDescription{
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 17px;
}

.lineInfo{
  font-size: 0.75rem !important;
  color: rgb(105, 109, 115);
  line-height: 17px;
}


/*Product list*/

.containerDetail
{
    width: 94%;
    height: auto;
    display: flex;
    flex-direction: column;
    border:solid 1px;
    margin: 2rem;
    background-color: white;
    font-size: 0.9rem;

    .headerSearch
    {
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;
        border-bottom: 1px solid #d8d8d8;
        width: 98%;
        padding: 25px 10px 15px 10px;
        align-items: flex-start;
        justify-items: center;

        .inputSearch{
            display: flex;
            width: 250px;
            margin: 0 45px 0 20px;
        }

        .completeProducts{
            display: flex;
        }


    }

    .headerList{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        justify-items: center;
        padding: 20px 10px 15px 10px;
        border-bottom: 1px solid #d8d8d8;
        color: rgb(0, 44, 108);
        

        .gtin{
            width: 180px;
            justify-content: center !important;
            align-items: center !important;
            justify-items: center !important;
            text-align: center;  
            margin:0 65px 0 0; 
        }

        .productDescription{
            width: 450px;
            display: flex;
        }

        .pagination{
            display: flex;
        }
    }

    .rowList{

        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #d8d8d8;

        .imageList, .productIcon
        {
            width: 60px;
            height: 60px;
            justify-content: center;
            align-items: center;
            justify-items: center;
            display: flex;
        }

        .imageList img{
            height: 60px;
        }

        .productIcon img{
            height: 30px;
        }

        .gtinCode{
            display: flex;
            width: 175px;
            padding-left:20px
        }

        .productDescription
        {
            display:flex;
            width: 620px;
        }

        .error
        {
            background-color: #fbafa5;
            color: #db1a00;
        }
    }

    .footerList
    {
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        align-items: center;
        height: 65px;
    }

}

.headerlinks{
  font-size: 11px !important;
  display: flex !important;
  justify-content: center;
  padding: 10 40px !important;
  list-style-type: none;
  align-items: center;
  border-bottom: 1px solid #ebf1f7 !important;
  margin-bottom: 0px !important;
}

.headerlinks li{
  padding: 0 8px;
}

//Avaliação
.containerRadio {
  input[type="radio"] {
    display: none;
  }

  .star1 {
    cursor: pointer;
    color: #ffc107;
    margin-right: 10px;
  }
  .star2 {
    cursor: pointer;
    color: #c4d7ed;
    margin-right: 10px;
  }
}

.aboutAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input:disabled{
  background-color: white !important;
}

.alert{
  display: flex !important;

  .box-icon-alert
  {
    display: flex !important;
    width: 4rem !important;
    height: 4rem !important;
  }

  .text-alert{
    margin-left: 0px !important;
  }
}

.borderbottom{
  border-bottom: solid 1px #c4d7ed !important;
  border-top:none !important;
}

header{
  position: relative;
  z-index: 100;
}

.titleDescriptionMin{
  display: none;
}
.pdl{
  padding-left:  25px;
}

@media (max-width: 767px){
  #contentRow {
      padding-top: 0px !important;
      margin-top:0px;
  }

  .maxContentApi{
    padding-top: 133px
  }

  .titlePrincipal{
    margin-top: 0px !important;
  }

  .titleDescriptionMin{
    display: block;
    color: #002c6c;
  }
  .banner{
    background-image: url("./Imagem4.png");
  }

  .px-4{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pdl{
    padding-left:  0px !important;
    padding-right:  0px !important;
  }
 .bg-color-ui-1 .container {
    padding-left:  0px !important;
    padding-right:  0px !important;
  }
}
.tabelafaq{
  border-width: 3px;
  border-style: solid;
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;
  border-color: rgb(0, 44, 108);
}
