.customWidth{
    max-width: 1100px;
}
.rowBoxApiCustom {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    padding-right: 15px;
    padding-left: 15px;
}