.content-progress{
    .item-progress{
        font-size:$font-size-little-one;
        color: $color-ui-8;
        font-weight: 400;
        margin-bottom: .125rem;
        margin-top: $spacer-3;
    }
    .progress {
        height: 1.5rem;
        font-size: .75rem;
        background-color: $color-ui-4;
        border-radius: .25rem;
    }
}