.banner{
   background-image: url("./Imagem3.png");
   height: 100% !important;
//    min-height: 620px;
   max-height: max-content !important;
   background-repeat: no-repeat;
   background-position: top center;
   background-size: 100%;
   padding:$spacer-6 0;
   position: relative;
   .overlay{
          &.overlay-black{
               background: rgba(0,0,0,.6);  
          }
        width: 100%;
        height: 100%;
        background: rgba(242,99,52,.8); 
        position: absolute;
        top: 0;
   }
   .gs1-h1{
        color: $color-white;
   }
   .gs1-subtitle{
        color: $color-white;
   }
} 

.maxContentApi{
     height: 100% !important;
     max-height: max-content !important;
     display: block;
     position: relative;
     background-color: #f0f5fa;
}
@media (max-width: 736px) {
     .boxApiActive::before {
          
          height: 25px !important;
                  
     
      }
    
    .bgBoxApi{
        display: flex !important;
    }
    .titlePrincipal{
        margin-top: 40px;
        font-size: 1.5rem !important;
        display: grid;
    }
    .titleDescription{
          font-weight: 400;
          line-height: 1.2;
          color: #002c6c;
          display: flex !important;
     }
     .rowBoxApi{         
         flex-direction: column !important;
        
     }
     .boxApi{
          width: 100% !important;
         max-width: 800px !important;
         height: 89px !important;
      }
      .boxApiActive{
          width: 100% !important;
         max-width: 800px !important;
         height: 89px !important;
         margin-bottom: 15px;
      }
      .boxSubtitle{
          display: none !important;
      }
      .boxContent{
          display: flex;     
      }
      .boxTitle{
          padding-left: 5px;
          padding-top: 5px;
      }
      .boxApiActive::after {
          bottom: -15px !important;
          height: 30px !important;
     }
}
 .boxTitle{
     margin-top: 0.25rem !important;
     margin-bottom: 0.25rem !important;
     font-weight: 400;
     font-size: 20px;
     color: #002c6c;
 }
.boxSubtitle{
     font-size: 0.75rem !important;
     font-weight: 400;
}


.titleDescription{
     display: none;
}
.wp-home{
     margin-top: 32px;
}
.rowTitleApis{
     margin-top: -50px !important;
    margin-bottom: 20px !important;
}
.boxApiActive{
     background: #002c6c;
     border: 1px solid #c4d7ed;
     border-radius: 4px;
     padding: 1.125rem;
     height: 100%;
     position: relative;
     cursor: pointer !important;
     color: white;
     max-width: 224px;
     max-height: 192px;
     width: 224px;
     height: 192px;
     .boxIcon{
          filter: invert(82%) sepia(57%) saturate(840%) hue-rotate(325deg) brightness(94%) contrast(111%);
     }
     .boxTitle{
          color: white;
     }
     -webkit-clip-path: polygon(0 0,0 200%,100% 200%,100% 25%,75% 0);
     clip-path: polygon(0 0,0 200%,100% 200%,100% 25%,75% 0);

 }
 .buttonEspecialGLN{
     max-width: 95px !important;
     width: 95px !important;
     padding-left: 22px !important;
     padding-right: 22px !important;
     padding-top: 13px !important;
     padding-bottom: 13px !important;
 }
 .bannertot{
     background: white;
     padding-top: 35px;
     padding-left: 35px;
     padding-right: 35px;
     padding-bottom: 35px;
     
 }
.boxApi{
     background: #f0f5fa;
     border: 1px solid #c4d7ed;
     border-radius: 4px;
     padding: 1.125rem;
     height: 100%;
     position: relative;
     width: 100%;
     max-height: 192px;
     cursor: pointer;
     color: #002c6c !important;
     max-width: 224px;
     width: 224px;
     height: 192px;
    
}
.boxApiActive::before {
     background-image: url('../public/download.svg');
     position: absolute;
     bottom: -25px;
     left: -10px;
     right: 0;
     height: 20px;
     background-repeat: no-repeat;
     background-size: cover; 
     content: "               ";
     
     

 }
.rowBoxApi{
     display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    padding-right: 15px;
    padding-left: 15px;
}
.imgcheck-green{
     width: 79px;
     height: 82px;
     margin-right: 20px;
}
// Feature Banner opção 02
.banner-model-two{
     position: relative;
     .content-banner{
          padding:$spacer-6 0;
          .gs1-h1{
               color: $color-white;
          }
          .gs1-subtitle{
               color: $color-white;
          }
     }
     .img-banner{
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          height: 100%;
          right: 0;
     }
}
@media (max-width: 736px) {
     .banner-model-two{
          .img-banner{
               background-size: cover;
               background-repeat: no-repeat;
               position: relative;
               height: 170px;
          }
     }
 }


 // Banner Interna
.banner-internal{
     &.banner-internal-btn{
          width: 100%;
          height: auto;
          position: relative;
          padding:$spacer-6 0;
          .overlay { 
               width: 100%;
               height: 100%;
               background: rgba(242,99,52,.8);
               position: absolute;
               top: 0;
          }
          img{
               width: 100%;
               height: 100%;
               object-fit: cover; 
               position: absolute; 
               z-index: 1;
               top: 0;
          }
          .gs1-subtitle,
          .content-btn{
               position: relative;
               z-index: 3;
          }
     }
     position: relative;
     width: 100%;
     height: 250px;
     overflow: hidden;
     .overlay {
          &.overlay-pink{
               background: rgba(240,85,135, .8);   
          }
         
          &.overlay-blue{
               background: rgba(0,44,108,.8);  
          }
          width: 100%;
          padding:$spacer-6 0;
          height: 100%;
          background: rgba(242,99,52,.8);
          position: absolute; 
          top: 0;
          z-index: 2;
     }
     h1{
          color: $color-white;
          position: relative;
          z-index: 3;
     }
     img{
          width: 100%;
          height: 100%; 
          object-fit: cover; 
     }
     .ms-rtestate-field{
          height: 100%; 
     }
}
@media (max-width: 736px) {
     .banner-internal{
          height: 230px;
          &.banner-mobile{
               img{
                    display: block;
               } 
          }
          &.overlay-setores{
               background: rgba(240,85,135, 1);   
          }
          &.overlay-blue{
               background: rgba(0,44,108, 1);  
          }
          .overlay {
               background: rgba(242,99,52,1);
               h1{
                    color: $color-white;
                    position: relative;
               }
          }
          /*img{
               display: none;
          }*/
     }
 }

 @media (max-width: 991px) {
     .banner-barcode{
          background: none;
      } 
 }

