//FAQ Component

.faq {
  .caption {
    h3 {
      font-weight: 400;
    }
  }
}

//Search Box Page
#SearchBox {
  .ms-srch-sbLarge {
    position: relative;
    z-index: 1;
    .ms-srch-sb-searchLink {
      color: $color-ui-6;
      display: flex;
      margin: 0;
      padding: 0;
      position: absolute;
      left: 0.75rem;
      top: 25%;
      &:hover,
      &:active {
        text-decoration: none;
        background: none;
      }
    }
    .ms-srch-sbLarge-fullWidth {
      background: $color-white;
      color: $color-ui-6;
      outline: 0;
      margin: 0;
      padding: 0.625rem 1.25rem 0.625rem 2.5rem;
      border: 1px solid $color-ui-3;
      border-radius: 0.25rem;
      font-size: 1.125rem;

      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: $color-ui-6;
      }
    }
  }
  .ms-srch-sb-border {
    border: 0;
  }
  .ms-srch-sb-borderFocused {
    border: 0;
  }
}

//Icon + text
.icon-text {
  width: 100%;
  float: left;
  .box-icon {
    width: 2rem;
    float: left;
    padding-right: $spacer-2;
    svg {
      fill: $color-forest-accessible-dark-1;
    }
  }
  .text-icon {
    width: 90%;
    float: left;
  }
}
//Search Results Item
.ms-srch-result {
  .card {
    border: 0;
    outline: none;
    box-shadow: none;
    transition: unset;
    margin-bottom: 2px;
    .card-body {
      padding: 1.5rem 0;
    }
  }
  .card:hover {
    box-shadow: none;
    transform: unset;
  }
}

//Search Results Count
.ms-srch-resultscount {
  font-weight: 400;
  margin-bottom: 0;
}
/*Search Pagination
.ms-srch-Paging {
  .busca-proxima-pagina,
  .busca-pagina-anterior {
    font-weight: 500;
    padding: 0.625rem 1.375rem;
    .bi-arrow-right-short,
    .bi-arrow-left-short {
      width: 1.563rem;
      height: 1.563rem;
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
    &:hover {
      .bi-arrow-right-short {
        -webkit-transform: translateX(4px);
        transform: translateX(4px);
        transition-duration: 0.2s;
        transition-timing-function: ease;
      }
      .bi-arrow-left-short {
        -webkit-transform: translateX(-4px);
        transform: translateX(-4px);
        transition-duration: 0.2s;
        transition-timing-function: ease;
      }
    }
  }
}*/

//Webpart de Listagem de Eventos do calndario

.wpEventosFiltros {
  .panel {
    .panel-title {
      a {
        color: $color-blue;
        font-size: 1rem;
        font-weight: 500;

        svg {
          display: none;
        }
      }
    }
    .panel-body {
      &.tipoEvento {
        a {
          font-size: 0.9375rem;
          padding: 4px 8px 4px 30px;
          background-position: left -6px;
          background-repeat: no-repeat;
          display: block;
          text-decoration: none;
          outline: none;
        }
        a:hover {
          background-position: left -65px;
        }
        a:active,
        a:focus,
        a.selecionado {
          background-position: left center;
        }
      }
    }
  }
}

// Spinner 
.small-spinner {
  width: 35px;
  height: 35px;
}

.large-spinner {
  width: 64px;
  height: 64px;
}

@media (max-width: 736px) {
  .wpEventosFiltros {
    .panel {
      .panel-title {
        a {
          svg {
            display: block;
          }
        }
      }
    }
  }
}

// Detalhe do Evento
.headEventos {
  padding:$spacer-6 0;
  background-color: $color-forest-accessible;
  .lnkVoltar {
    font-size: 0.938rem;
    color: $color-white;
    svg {
      width: 1.875rem;
    }
  }
  h1.tituloPagina {
    color: $color-white;
  }
  .list-events {
    li {
      color: $color-white;
      svg {
        width: 1rem;
        margin-right: $spacer-2;
      }
    }
  }
}
//class color
.color-white{
  color: $color-white; 
}
.linktitle{
  color: #002c6c;
}
.styles-module_tooltip__mnnfp{
  max-width: 250px !important;
  text-align: center;
}
.linkItem{
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  color: #002c6c;
  padding-bottom: 6px;
  padding-top: 18px;
}
.linkblue{
  font-size: 0.95rem !important;
  color: #00799e;
  text-decoration: none;
  font-weight: 400;
}
.linkred{
  font-size: 12px;
  color: #cd3c0d;
  text-decoration: none;
  font-weight: 400;
}
.languageLink{
  font-size: 0.95rem !important;
  font-weight: 400;
  color: #262626;
  
}