.bg-color-blue,
.bg-blue{
    background-color: $color-blue;
}
.bg-color-ui-1{
    background-color: $color-ui-1;
}
.bg-color-ui-2{
    background-color: $color-ui-2;
}
.bg-orange-accessible{
    background-color: $color-orange-accessible;
}
.bg-sky-accessible{
    background-color: $color-sky-accessible;
}
.bg-grass-accessible{
    background-color: $color-grass-accessible;
}
.bg-color-orange{
    background-color: $color-orange;
} 
.bg-color-white{
    background-color: $color-white;
}
.bg-color-raspberry{
    background-color: $color-raspberry;
}
