.calendar {
  background-color: $color-white;
  font-size:$font-5;
  color: $color-ui-8;
  tr {
    &:first-child {
      td {
        border-top: none;
      }
    }
    &:hover{
      td {
        &.w-15 {
          width: 15%;
          text-align: center;
          a {
              svg {
              -webkit-transform: translateX(4px);
              transform: translateX(4px);
              transition-duration: 0.2s;
              transition-timing-function: ease;
              color: $color-blue;
            }
          }
        }
        .description-event{
          a{
              text-decoration: none;
              color: $color-blue;
          }
        }
      }
    }
    td {
      vertical-align: middle;
      border-color: $color-ui-3;
      &.w-15 {
        width: 15%;
        text-align: center;
        a {
          transition-duration: 0.2s;
          transition-timing-function: ease;
          color: $color-link-accessible;
          svg {
            width: 2.5rem;
            transition-duration: 0.2s;
            transition-timing-function: ease;
          }
          &:hover {
            svg {
              -webkit-transform: translateX(4px);
              transform: translateX(4px);
              transition-duration: 0.2s;
              transition-timing-function: ease;
            }
          }
        }
      }
      .date-event {
        font-size:$font-1;
        color: $color-blue;
        line-height: $line-height-heading;
      }
      .mes-event {
        font-size:$font-size-little;
        color: $color-blue;
        font-weight: 500;
      }
      .local-event {
        font-size:$font-size-little;
        color: $color-blue;
        font-weight: 500;
      }
      .description-event{
        a{
          color: $color-link-accessible;
          text-decoration: none;
          &:hover{
            text-decoration: none;
            color: $color-blue;
          }
        }
      }
    }
  }
}
