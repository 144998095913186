.customColumns{
    border: 0px transparent;
    display: flex;
    flex-direction:column;
}

@media (max-width: 640px) {
    .customColumns{
        display: flex;
        flex-direction:column;
    }
}