.horizontal-progress-stepper {
    margin-top: 3rem;
    margin-bottom: 3rem;

    .progress {
        background-color: $color-ui-4;
        height: 4px;
        
        .progress-bar {
            background-color: $color-blue;
        }
    }
    .steps {
        margin-top: 0.188rem;

        a, span {
            font-size: 0.938rem;
            color: $color-ui-8;

            &:hover {
                color: $color-ui-8;
            }
        }
        svg {
            font-size: 1rem;
            color: $color-ui-8;
        }
        .active {
            svg {
                color: $color-blue;
            }
        }
    }
}