.cardimagewrap{
  height: 170px;
  position: relative;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25rem 0.25rem 0 0;
  object-fit: cover;
}
.knowmoreTitle{
  color: #002c6c;
  line-height: 1.4;
  font-size: 2.0rem;
  font-weight: 200;
  font-family: Gotham SSm A,Gotham SSm B,Verdana,sans-serif;
}
.knowmoreText{
  word-wrap: break-word;
  font-size: 1.125rem;
  font-family: Gotham SSm A,Gotham SSm B,Verdana,sans-serif;
  color: #262626;
}
@media (max-width: 767px){
  .more {
    display: none;
  }  
}
.more {
  width: 100%;
  background: #002c6c;
  background-size: cover;
  box-shadow: 0px 3px 6px #0000000d;

  .more_group {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    row-gap: 2rem;
    color:#fff;

    a {
      background-color: #CD3C0D;
      border-color: #cd3c0d;
      padding: 15px;
      color: #ffffff;
      font-size: 1rem;
      border-radius: 3px;
      &:hover {
        transition: 0.3s;
        color: white;
        background-color: #9b3411;
      }
    }

    a:hover{
      transition: 0.3s;
      background-color: #9b3411;
    }
  }
}
.verify_gs1 {
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  padding-top: 2.5rem;
  padding-bottom: 2rem;

  div {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }
}
.presentation {
  width: 100%;
  min-height: 200px;
  padding-top: 2rem;
  background-color: #f0f5fa;
  .block_presentation {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    .t_presentation {
      min-height: 500px;
      padding-top: 2rem;
    }
  }
}


.org_presentation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}


.card_p {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 1rem;
    height: 1px;
  }
}

@media (max-width: 991px){
  .navbarHome {
      display: none;
  }
}
@media (max-width: 580px){
  .row {
      padding-left: 15px;
  }

  .imgConsultaType {
    margin-top:  100px !important;
  }
}
@media (max-width: 760px){

  .textConsultaType {
    padding-left: 15px !important;
  }
  .imgConsultaType {
    margin-top:  50px !important;
  }
}