.customSpanOptions{
    width: 100%;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
}

.form-check-label-custom{
    font-size: 14px;
    color: #1c4578;
    cursor: pointer;
    font-weight: 400;
}

#customDisplay {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

@media(max-width: 640px){
    #customDisplay{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .customSpanOptions{
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
}
