.dropdown {
  a.btn,
  button.btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: calc(2rem + 0.75rem + 1px);
    padding-left:$spacer-3;
    border: 1px solid $color-ui-3;
    font-size: 0.938rem;
    font-weight: 400;
    &::placeholder {
      color: $color-ui-6;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $color-ui-6;
      font-size: 0.938rem;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $color-ui-6;
      font-size: 0.938rem;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $color-ui-6;
      font-size: 0.938rem;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $color-ui-6;
      font-size: 0.938rem;
    }
    &:placeholder-shown {
      color: $color-ui-6;
    }
    &:active,
    &:focus {
      box-shadow: none;
      outline: $color-blue;
    }
    &:hover {
      background: $color-ui-2;
      border-radius: 0.25rem;
      color: $color-blue;
    }
    &:disabled {
      color: $color-ui-5;
    }
  }

  .dropdown-toggle:after {
    display: none;
  }

  .dropdown-menu {
    margin-top: 0;
    width: 100%;
    .dropdown-item {
      height: calc(2rem + 0.75rem + 1px);
      padding: 0.75rem 1.375rem;
      margin-bottom: 0;
      &:hover {
        background: $color-ui-2;
        color: $color-blue;
      }
      &:active,
      &:focus {
        box-shadow: 0 0 0 0.125rem rgba(0, 44, 108, 1);
        outline: $color-blue;
      }
    }
  }
}
