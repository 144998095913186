.carousel{
    &.carousel-testimonial{
        .slick-list{
            padding-right: 0;
        }
        .box-carousel{
            padding:3rem !important;
            height: auto;
        }
        .slick-arrow{
            padding-right:0;
            position: absolute;
            top: 50%;
            .control{ 
                width: 3.125rem;
                height: 3.125rem;
                padding: 0.625rem 0.938rem;
                display: block; 
                border-radius: 100%;
                border: 1px solid $color-orange;
                background-color: $color-orange;
                box-shadow: 0 .125rem .188rem rgba(0, 44, 108, 0.2);
                color: $color-white;
                position: absolute;
                right: 0;
                bottom: $spacer-4;
                cursor: pointer;
                z-index: 9;
                svg{
                    width: auto;
                    height: 1rem;
                }
                &.prev{
                    left: 0;
                    top: 50%;
                    margin-top: -25px;
                    &:hover {
                        box-shadow: 0 0.625rem 1.875rem rgba(0, 44, 108, 0.2);
                        -webkit-transform: translatex(-4px);
                        transform: translatex(-4px);
                        transition-duration: 0.2s;
                        transition-timing-function: ease;
                      }
                      &:active,
                      &:focus {
                        outline: 2px solid $color-blue;
                        box-shadow: none;
                      }
                }
                &.next{
                    right: 0;
                    top:50%;
                    margin-top: -25px;
                    &:hover {
                        box-shadow: 0 0.625rem 1.875rem rgba(0, 44, 108, 0.2);
                        -webkit-transform: translatex(4px);
                        transform: translatex(4px);
                        transition-duration: 0.2s;
                        transition-timing-function: ease;
                      }
                      &:active,
                      &:focus {
                        outline: 2px solid $color-blue;
                        box-shadow: none;
                      }
                }
            }
        }

    }

    .box-carousel{
        padding:$spacer-6 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 413px;
        .card-carousel{
            background-color: $color-white;
            padding:$spacer-5;
            border-radius: 0.25rem;
            .title-card-carousel{
                font-size:$font-4;
                font-weight: 400;
                color: $color-blue;
                line-height: $line-height-tight;
            }
            .description-card-carousel{
                font-size: 1rem;
                line-height: 1.375;
                color: $color-ui-8;
                margin-bottom:$spacer-3;
            }
            
        }
       
    }
    .control{ 
        width: 3.125rem;
        height: 3.125rem;
        padding: 0.625rem 0.938rem;
        display: block; 
        border-radius: 100%;
        border: 1px solid $color-ui-2;
        background-color: $color-white;
        box-shadow: 0 .125rem .188rem rgba(0, 44, 108, 0.2);
        color: $color-blue;
        position: absolute;
        right: 0;
        bottom: $spacer-4;
        cursor: pointer;
        z-index: 9;
        svg{
            width: auto;
            height: 1rem;
        }
        &.prev{
            right: 74px;
            top: 339px;
        }
        &.next{
            right: 0;
        }
        &:hover{
            box-shadow: 0 0.625rem 1.875rem rgba(0,44,108,.2);
            transform: translateY(-4px);
            transition-duration: .2s;
            transition-timing-function: ease;
        }
    }
}

@media (min-width: 991px) {
    .carousel {
        .slick-arrow{
            padding-right: 6.188rem;
        }
        .slick-list{
            padding-right: 10.313rem;
        }
        .box-carousel{
            padding-left: 10.313rem !important;
        }
    }
}

@media (min-width: 768px) {
    .carousel {
        .slick-arrow{
            padding-right: 8.188rem;
        }
        .slick-list{
            padding-right: 8.313rem;
        }
        .box-carousel{
            padding-left: 8.313rem !important;
        }
    }
}

@media (max-width: 767px) {
    .carousel{
        &.carousel-testimonial{
            .box-carousel{
                padding:0 !important;
                height: auto;
                .testimonial{
                    .card{
                        padding:$spacer-5 0;
                        .card-body{
                            padding:0;
                        }
                    }
                }
            }
            .slick-arrow{
                position: absolute;
                top: 100%;
                .control{ 
                    &.prev{
                        left: 60%;
                        top: 50%;
                        margin-top: -25px;
                    }
                    &.next{
                        right: 0;
                        top:50%;
                        margin-top: -25px;
                    }
                }
            }
            margin-bottom: 4rem;
        }
        margin-bottom: 14rem;
        .box-carousel{
            height: 220px;
            .card-carousel{
                border: 1px solid $color-ui-2;
                box-shadow: 0 .125rem .188rem rgba(0, 44, 108, 0.2);
                position: relative;
                top: 50%;
            }
        }
        .control{ 
            bottom: 0;
            &.prev{
                right: 90px;
                top: 370px;
            }
            &.next{
                right: 15px;
                top: 150px;
            }
        }
        .slick-list {
            overflow: inherit;
        }
    }


}
@mixin ie-only {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .carousel{
            .control{ 
                &.prev{
                    top: 345px;
                }
                
            }
        }
    }
}