// Variables
// ============================================================================

// RFS config
// ----------------------------------------------------------------------------

$rfs-base-value: 0.9rem;
$rfs-factor: 7;
$rfs-breakpoint: 1200px;

// Type
// ----------------------------------------------------------------------------

$font-root: 100%;
$font-body: 1.125rem;

$font-1: 2.5rem;
$font-2: 2rem;
$font-3: 1.625rem;
$font-4: 1.25rem;
$font-5: 1.125rem;
$font-6: 1.125rem;

$font-display-1: 3.625rem;
$font-display-2: 3.125rem;

$font-size-intro: 1.375rem;
$font-size-big: 1.375rem;
$font-size-little: 0.95rem;
$font-size-little-one: 0.938rem;
$font-size-tiny: 0.625rem;
$font-size-subtitle: 1.5rem;

$line-height-default: 1.625;
$line-height-tight: 1.4;
$line-height-heading: 1.2;

// Spacing
// ----------------------------------------------------------------------------

$spacer-1: 0.25rem;
$spacer-2: 0.5rem;
$spacer-3: 1.125rem;
$spacer-4: 1.5rem;
$spacer-5: 3rem;
$spacer-6: 4rem;
$spacer-7: 5rem;
