.footer{
    margin-bottom:$spacer-3;
    .logo{
        max-width: 115px;
    }
    .list-navegation-footer{
        padding: 0;
        margin: 0;
        list-style: none;
        > li {
            width: 100%;
            display: block;
            margin-bottom: 0;
            .title-item-footer{
                display: table;
                font-size: 1rem;
                font-weight: 500;
                color: $color-blue;
                &:hover {
                    color: $color-blue;
                  }
                
                  &:active {
                    color: $color-blue-dark-2;
                  }
                
                  &:focus {
                    outline-color: $color-blue;
                  }
                  &:visited {
                    color: $color-blue;
                  }
            }
            .item-footer{
                display: table;
                font-size: .875rem;
                font-weight: 400;
                color: $color-link-accessible;
                margin-bottom: .875rem;
                &:last-child{
                    margin-bottom: 0;
                }
                &:hover {
                    color: $color-blue;
                  }
                
                  &:active {
                    color: $color-blue-dark-2;
                  }
                
                  &:focus {
                    outline-color: $color-blue;
                  }
            }
        }
    }
    
    .list-utility-link{
        padding: 0;
        margin: 0;
        list-style: none;
        > li{
            float: left;
            margin-right: 1rem;
            margin-bottom:$spacer-3;
            .utility-link{
                font-size: .75rem;
                font-weight: 400;
                text-decoration: underline;
                color: $color-link-accessible;
                &:hover {
                color: $color-blue;
                }
            
                &:active {
                color: $color-blue-dark-2;
                }
            
                &:focus {
                outline-color: $color-blue;
                }
              
            }
        }
    }
    .text-footer{
        font-size: .75rem;
        font-weight: 400;
        color: $color-ui-6;
        line-height: 1.18;
    }
    .line-h{
        width: 100%;
        height: 1px;
        display: block;
        background-color: $color-ui-3;
        margin:$spacer-4 0;
    }
    .collapse:not(.show) {
        display: block;
    }
    .title-phone{
        display: table;
        font-size: 1rem;
        font-weight: 500;
        color: $color-blue;
    }
    .central-phone{
        font-size: 1rem;
        color: $color-ui-6;
    }
}
@media (max-width: 736px) {
    .footer{
        .list-navegation-footer{
            > li {
                .title-item-footer{
                    margin-bottom: 1.125rem;
                }
                .item-footer{
                    display: none;
                }
            }
        }  
        .list-utility-link{
            > li{
                width: 100%;
                &:last-child{
                    margin-bottom:$spacer-4
                }
            }
        }
        .collapse:not(.show) {
            display: block;
        }
    }
}
