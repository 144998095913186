.statistic-tile{
    padding:$spacer-4;
    background: $color-ui-1;
    .number-statistic{
        font-size: 3.125rem;
        font-weight: 300;
        letter-spacing: -1px;
        line-height: 3.125rem;
    }
    .description-statistic{
        font-size:$font-size-little-one;
        font-weight: 400;
        color: $color-ui-8;
    }
} 