// Utilities
// ============================================================================

@import "variables";
@import "node_modules/rfs/scss"; 

$spacers: (
  "gs1-spacer-1": $spacer-1,
  "gs1-spacer-2": $spacer-2,
  "gs1-spacer-3": $spacer-3,
  "gs1-spacer-4": $spacer-4,
  "gs1-spacer-5": $spacer-5,
  "gs1-spacer-6": $spacer-6,
  "gs1-spacer-7": $spacer-7,
);

@each $spacer, $value in $spacers {
  .#{$spacer} {
    @include margin-bottom($value);
  }
}

:root {
  @each $spacer, $value in $spacers {
    @include rfs($value, --#{$spacer});
  }
}
