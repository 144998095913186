.py-5{
    padding-top:$spacer-5;
    padding-bottom:$spacer-5;
}
.pt-5{
    padding-top:$spacer-5;
}
.pb-5{
    padding-bottom:$spacer-5;
}
.py-6{
    padding-top:$spacer-6;
    padding-bottom:$spacer-6;
}
.pt-6{
    padding-top:$spacer-6;
}
.pb-6{
    padding-bottom:$spacer-6;
}
.py-7{
    padding-top:$spacer-7;
    padding-bottom:$spacer-7;
} 
.pt-7{
    padding-top:$spacer-7;
}
.pb-7{
    padding-bottom:$spacer-7;
}
.my-7{
    margin-top:$spacer-7;
    margin-bottom:$spacer-7;
}
.mt-7{
    margin-top:$spacer-7;
    margin-bottom:$spacer-7;
} 