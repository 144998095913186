input[type='button'],
input[type='reset'],
input[type='submit'],
button,
.btn {
  font-size:$font-size-little;
  padding: 0.75rem 1.375rem;
  line-height: $line-height-tight;
  border: 1px solid transparent;
  text-decoration: none;
  font-family: Gotham SSm A, Gotham SSm B, Verdana, sans-serif;
}
.ms-wpadder button,
input[type='button'],
input[type='reset'],
input[type='submit'],
button,
.btn-primary {
  border-radius: .25rem;
  color: $color-white;
  background-color: $color-orange-accessible;
  border-color: $color-orange-accessible;
  &:hover {
    background-color: $color-orange-accessible-dark-1;
    border-color: $color-orange-accessible-dark-1;
  }
  &:active {
    background-color: $color-orange-accessible-dark-2;
    border-color: $color-orange-accessible-dark-2;
    text-decoration: none;
    box-shadow: none;
  }
  &:focus {
    background-color: $color-orange-accessible;
    border-color: $color-orange-accessible;
    outline: 2px solid $color-blue;
    box-shadow: none;
  }
  &:visited {
    color: $color-white;
  }
  &:not(:disabled) {
    &:not(.disabled).active {
      background-color: $color-orange-accessible-dark-2;
      border-color: $color-orange-accessible-dark-2;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    &:not(.disabled) {
      &:active {
        background-color: $color-orange-accessible-dark-2;
        border-color: $color-orange-accessible-dark-2;
        box-shadow: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}
.btn-primary-blue {
  color: $color-white;
  background-color: $color-blue;
  border-color: $color-blue;
  &:hover {
    background-color: $color-blue-dark-1;
    border-color: $color-blue-dark-1;
    color: $color-white;
  }
  &:active {
    background-color: $color-blue-dark-2;
    border-color: $color-blue-dark-2;
    text-decoration: none;
    color: $color-white;
  }
  &:focus {
    background-color: $color-blue;
    border-color: $color-blue;
    box-shadow: none;
    text-decoration: none;
    color: $color-white;
  }
  &:visited {
    color: $color-white;
  }
  &:not(:disabled) {
    &:not(.disabled).active {
      background-color: $color-blue-dark-2;
      border-color: $color-blue-dark-2;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border-color: $color-blue-dark-2;
      }
    }
    &:not(.disabled) {
      &:active {
        background-color: $color-blue-dark-2;
        border-color: $color-blue-dark-2;
        box-shadow: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
.btn-default-with-arrow {
  font-weight: 500;
  padding: 0.625rem 1.375rem;
  .bi-arrow-right-short,
  .bi-arrow-left-short {
    width: 1.563rem;
    height: 1.563rem;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }
  &:hover {
    .bi-arrow-right-short {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
    .bi-arrow-left-short {
      -webkit-transform: translateX(-4px);
      transform: translateX(-4px);
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }
}
.btn-outline-primary {
  color: $color-orange-accessible;
  background-color: $color-white;
  border-color: $color-orange-accessible;
  &:visited {
    color: $color-orange-accessible;
  }
  &:hover {
    background-color: $color-orange-accessible-dark-1;
    border-color: $color-orange-accessible-dark-1;
    color: $color-white;
  }
  &:active {
    background-color: $color-orange-accessible-dark-2;
    border-color: $color-orange-accessible-dark-2;
    text-decoration: none;
  }
  &:focus {
    background-color: $color-orange-accessible;
    border-color: $color-orange-accessible;
    box-shadow: none;
    text-decoration: none;
    outline: 2px solid $color-blue;
    color: $color-white;
  }

  &:not(:disabled) {
    &:not(.disabled).active {
      background-color: $color-orange-accessible-dark-2;
      border-color: $color-orange-accessible-dark-2;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        border-color: $color-orange-accessible;
        outline: none;
      }
    }
    &:not(.disabled) {
      &:active {
        background-color: $color-orange-accessible-dark-2;
        border-color: $color-orange-accessible-dark-2;
        box-shadow: none;
        &:focus {
          box-shadow: none;
          border-color: $color-orange-accessible;
          outline: none;
        }
      }
    }
  }
}
.btn-sm {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.563rem 1rem;
  line-height: 1.25rem;
}
.link-text {
  color: $color-link-accessible;
  transition: color 0.1s ease;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  padding: .24rem 0;
  .bi-arrow-right-short {
    width: 1.563rem;
    height: 1.563rem;
    transition-duration: 0.2s; 
    transition-timing-function: ease;
  }
  &:hover {
    color: $color-blue !important;
    text-decoration: none;
    .bi-arrow-right-short {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
      transition-duration: 0.2s;
      transition-timing-function: ease;
    }
  }
  &:active {
    color: $color-blue-dark-2;
  }

  &:focus {
    outline: 2px solid $color-blue;
    color: $color-link-accessible;
  }

  &:visited {
    color: $color-link-accessible;
  }
}
.btn-group {
  .btn-default {
    background-color: $color-ui-1;

    & + label {
      border-left: 1px solid $color-ui-3;
    }
  
    span {
      color: $color-blue;
      font-size: 0.875rem;
      font-weight: 500;
    }
  
    &:hover {
      background-color: $color-ui-2;
    }
  
    &:focus, &.focus {
      border: 2px solid $color-blue !important; 
      box-shadow: none;
    }
  
    &:active, &.active {
      background-color: $color-ui-3;
    }
  
    &:disabled {
      background-color: $color-ui-4;
  
      span {
        color: $color-ui-5;
      }
    }
  }
}
@media (max-width: 736px) {
  .link-text {
    .bi-arrow-right-short{
      display: inline-block !important; 
    }
  }
}
