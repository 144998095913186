.webpart-exibicao-biblioteca{
    .list-unstyled{
        height: 100%;
        list-style: none;
        border-radius: .25rem;
        padding: 0;
        li{
            margin-bottom: 0;
            a{
                font-size:$font-size-little-one;
                color: $color-link-accessible;
                font-weight: 400;
                padding:$spacer-3;
                border-bottom:1px solid $color-ui-3;
                display: block;
                &:active,
                &:focus,
                &:hover{
                    background-color: $color-ui-3;
                    color: $color-blue; 
                    outline: none;
                }
            }
        }
    }
}
@media (max-width: 736px) {
    .webpart-exibicao-biblioteca{
        .list-unstyled{
            li{
                a{
                    padding:$spacer-3 0;
                }
            }
        }
    }
}
