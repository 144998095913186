.content-tabs{
    border-bottom: 2px solid $color-ui-4;
   .nav-item{
       &.show{
           .nav-link{
            border-top:none;
            border-left:none;
            border-right:none;
            background-color: transparent;
            border-radius: 0;
           }
       }
       &:hover,
       &:focus{
        background-color: $color-ui-2;
        border:none;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
         .nav-link{
            color: $color-blue;
         }
       }
   }
   .nav-link{
    font-size:$font-size-little-one;
       color: $color-ui-8;
       font-weight: 500;
       border-top:none;
       border-left:none;
       border-right:none;
       border-bottom: 2px solid transparent;
       padding:$spacer-2 $spacer-4;
       &.active{
        border-top:none;
        border-left:none;
        border-right:none;
        background-color: transparent;
        color: $color-blue;
        font-weight: 500;
        border-bottom: 2px solid $color-orange; 
       }
      
   }
}  
.tab-content{
    padding:$spacer-5
}

@media (max-width: 736px){
    .content-tabs{
       .nav-item{
           width: 100%;
           text-align: center;
            &:hover,
            &:focus{
                background-color: transparent;
                border:none;
            }
       }
    }
}