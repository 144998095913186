.datatablepagination
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: solid 1px #F0F5FA;

    .pageresults
    {
        width: 100%;
        display:flex;
        flex-direction: row;
        align-items: center;
        gap: 35px;
        font-size: 15px;
        margin-left: 12px;
    }

    .shownumberperpage
    {
        gap:5px;
        display: flex;
        align-items: center;
    }

    .page-item.active .page-link 
    {
        z-index: 0;
        color: #002C6C;
        background-color: rgb(186,210,233);
        border: 1px solid rgb(186,210,233);
    }

    .page-item.active .page-link:focus 
    {
        z-index: 0;
        color: #002C6C;
        background-color: rgb(186,210,233);
        border: 1px solid rgb(186,210,233);
    }

    .page-link 
    {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #002C6C;
        text-decoration: none;
        font-size:14px;
        width: 40px;
        height: 40px !important;
        margin-top:-3px !important;
        background-color: #F0F5FA;
        border-right: 1px solid rgb(186,210,233);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding: 0px 10px;
        border-radius: 0;
    }

    .page-link:hover 
    {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #002C6C;
        background-color: rgb(186,210,233);
        border-color: solid 1px rgb(186,210,233);
        text-decoration: none;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .page-end
    {
        //border-right: none !important;
    }

    .custompagination 
    {
        display: flex;
        padding-left: 0;
        list-style: none;
        margin-right:12px;
    }

    .page-item
    {
        display: flex;
        height: 40px !important;
        //overflow: hidden;
        
        button
        {
            padding: 0;
            margin:0;
        }

        button:focus
        {
            background-color: rgb(186,210,233);
            border: 1px solid rgb(186,210,233);
            box-shadow: none;
        }

    }

    select 
    {
        width: 100%;
        padding: 0.3rem 0.3rem;
        border: 1px double #c4d7ed;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
        color: #252525;
        outline: 1px solid #c4d7ed;
    
        background: transparent;
    
        option 
        {
            padding: 0.6rem 0.9rem;
        
            &::before {
                content: ">";
                height: 10px;
                display: block;
            }
        
            &::after {}
        }
    }

    ul
    {
        margin-top: 30px !important;
    }
}
