.list-group{
    height: 100%;
    list-style: none;
    border:1px solid $color-ui-2;
    border-radius: .25rem;
    padding: $spacer-3 0;
    .item-list-group{
        margin-bottom: 0;
        h6{
            font-weight: 500;
            padding:0 $spacer-3;
            margin-bottom: $spacer-3;
        }
        .link-list-group{
            font-size:$font-size-little-one;
            color: $color-link-accessible;
            font-weight: 400;
            padding:0.5rem  $spacer-3 0.5rem $spacer-3;
            display: block;
            &:active,
            &:focus,
            &:hover{
                background-color: $color-ui-2;
                color: $color-blue; 
                outline: none;
                text-decoration: none;
            }
            svg{
                width: 1rem;
                display: inline-block;
                margin-top: -4px;
            }
        }
    }
}