/* Web Part - Galeria de Imagens */

.webpart-gdi h4 {
	display: table-cell;
	width: 100%;
	min-height: 44px;
	vertical-align: top;
	font-weight: normal;
	color: $color-blue;
    float: left;
    padding-top: 0.75rem;
    margin-bottom: 0px;
    margin-top: 0px;
}

.webpart-gdi-topo {
	display: table;
	width: 100%;
	border-bottom: 1px solid #B1B1B7;
	padding-bottom: 0.625rem;
	margin-top: -10px;
	margin-bottom: 0.625rem;
}

.webpart-gdi-imagens {
    height: 120px;
	position: relative;
    overflow: hidden;
	padding: 0.625rem 0;
}

.webpart-gdi-navegacao {
	display: table-cell;
	vertical-align: top;
	text-align: right;
	white-space: nowrap;
	padding-top: 0.75rem;
	padding-left: 0.938rem;
	padding-right: 0;
}

.webpart-gdi-navegacao a span{
	color: $color-orange;
	padding-bottom: 0;
}

.webpart-gdi-overflow {
	position: absolute;
    overflow: hidden;
    width: 99999px;
}

.webpart-gdi-overflow a {
    display: block;
    float: left;
    width: 150px;
    overflow: hidden;
    margin-right: 0.75rem;
}

.webpart-gdi-overflow a, .webpart-gdi-overflow a img {
    height: 115px;
}

.webpart-gdi-overflow a img {
    display: block;
    position: relative;
    border: 0;
}
