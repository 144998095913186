.customWithManyOther{
    max-width: 1100px;
    margin: 0px;
}
.customPadding{
    margin-right: 0px;
    padding: 0px;
}
.customPaddingKeys{
    border: 0px solid transparent;
}
.buttonRowSearcManyCustom {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.chooseFileCustom{
    padding: 1rem;
    color: #002C6C;
    background: #C4D7ED;
    font-size: 0.9rem;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    width: 180px;
    margin-bottom: 0px !important;

}
.buttonEspecialGLNCustom{
    width: 95px !important;
    padding-left: 22px !important;
    padding-right: 22px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}

.btnSearchMultipleCustom{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2rem;
  }


@media(max-width: 640px){
    .buttonRowSearcManyCustom{
        flex-direction: column;
        align-items: center;
    }
    .chooseFileCustom{
        width: 95%;
    }
    .customPaddingKeys{
        padding: 20px;
    }
    .btnSearchMultipleCustom{
        width: 100%;
    }
    .buttonEspecialGLNCustom{
        width: 100% !important;
        margin-left: 8px;
        margin-right: 8px;
    }
    
}