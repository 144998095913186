.alert{
    width: 100%;
    float: left;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 4px solid transparent;
    padding: $spacer-3 $spacer-4 $spacer-4 $spacer-3;
    .box-icon-alert{
        width: 1.68rem;
        float: left;
        padding-right: $spacer-2;
        margin-top: -.313rem;

        svg {
            width: 19px;
        }
    }
    .text-alert{
        width: 93%;
        float: left;
        p{
            font-size:$font-size-little-one;
            margin-bottom: 0;
        }
    }
    
}
.alert-warning{
    background-color: $color-tangerine-accessible-light-2;
    border-color: $color-tangerine;
    .box-icon-alert{
        svg{
            fill: $color-tangerine-accessible;
        }
    } 
    .text-alert{
        h5{ 
            color: $color-tangerine-accessible;
        }
        p{
            color: $color-ui-8;
        }
    }
    
}

.alert-success{
    background-color: $color-forest-accessible-light-2;
    border-color: $color-forest-accessible;
    .box-icon-alert{
        svg{
            fill: $color-forest-accessible-dark-1;
        }
    }
    .text-alert{
        h5{
            color: $color-forest-accessible-dark-1;
        }
        p{
            color: $color-ui-8;
        }
    }
}

.alert-danger{ 
    background-color: $color-danger-light-2;
    border-color: $color-danger;
    .box-icon-alert{
        svg{
            fill: $color-danger;
        }
    }
    .text-alert{
        h5{            
            color: $color-danger;
        }
        p{
            color: $color-ui-8;
        }
    }    
}

.alert-info{
    background-color: $color-ui-2;
    border-color: $color-blue;
    .box-icon-alert{
        svg{
            fill: $color-blue;
        }
    }
    .text-alert{
        h5{
            color: $color-blue;
        }
        p{
            color: $color-ui-8;
        }
    }    
}

@media (max-width: 991px){
    .alert{
        .box-icon-alert{
            width: 5%;
        }
    }
}
@media (max-width: 736px){
    .alert{
        .box-icon-alert{
            width: 9%;
        }
        .text-alert{
            width: 90%;
            float: left;
          
        }
    }
}