.btn-pagination{
    padding: $spacer-2;
    background-color: $color-ui-1;
    color: $color-blue !important;
    border-radius: .24rem;
    &:focus {
        outline: 2px solid $color-blue;
    }
}
//Search Pagination
.ms-srch-result {
    
    #Paging{
        text-align: left;
        margin: 0;
        li{
            float: left;
            height: 40px;
            &#PagingSelf{
                a{
                    background-color: $color-ui-3;
                }
            }
            a{
                padding:.625rem 1rem;
                background-color: $color-ui-1;
                border-right: 1px solid $color-ui-3;
                float: left;
                font-size: .875rem;
                font-weight: 500;
                color: $color-blue !important;
                top: 0;
            }
            a.busca-proxima-pagina{
                border-radius: 0 .24rem .24rem 0;
                border-right: none;
            }
            a.busca-pagina-anterior{
                border-radius: .24rem 0 0 .24rem;
            }
            a.busca-proxima-pagina,
            a.busca-pagina-anterior {
                padding:.625rem;
                color: $color-blue !important;
                .bi-arrow-right-short,
                .bi-arrow-left-short {
                width: 1.3rem;
                transition-duration: 0.2s;
                transition-timing-function: ease;
                }
                &:hover {
                    .bi-arrow-right-short {
                        -webkit-transform: translateX(4px);
                        transform: translateX(4px);
                        transition-duration: 0.2s;
                        transition-timing-function: ease;
                    }
                    .bi-arrow-left-short {
                        -webkit-transform: translateX(-4px);
                        transform: translateX(-4px);
                        transition-duration: 0.2s;
                        transition-timing-function: ease;
                    }
                }
            }
        }
        
    }
}