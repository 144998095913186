ul.nav{
  border-bottom-color: lightgray;
  border-bottom-style: solid;
  border-bottom-width: medium;
  width: 100%;
}
ul.nav li:hover{
  background: #e5f0fc;
  border-radius: 0.25rem;
}
.propertyvalueCustom {
  font-size: 0.75rem !important;
  color: rgb(0, 44, 108);
}
ul.nav li {
  cursor: pointer;
  margin-bottom: -2px;
  padding-bottom: 10px;
  color: #262626;
  display: block;
  position: relative;
  font-weight: 500;
  font-style: normal;
  font-size: 0.875rem;
  width: 250px;
  text-align: center;
}
ul.nav li.active {
  color: #002c6c;
  font-weight: bold;
  position: relative;
  display: block;
  border-bottom-color: #cd3c0d;
  border-bottom-style: solid;
  border-bottom-width: medium;
  background: #f0f5fa;
  border-radius: 0.25rem;
}
.outlet{
  margin-right: 15px;
}
.custom-border3{
  border: none;
  line-height: 1em;
}

@media (max-width: 500px){
  ul.nav li {
      width: 100px !important;
  }
}
