/*--------------------------------------------------------------
# Mobile Menu
--------------------------------------------------------------*/
.nav-mobile {
  padding: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;

  .navbar {
    padding: 0;
    justify-content: flex-end;
  }

  .navbar-header {
    padding-right: 0.938rem;
    display: flex;

    .search-box {
      width: 42px !important;
      margin-right: 0.75rem;

      .search {
        color: $color-blue !important;
        text-decoration: none;

        svg {
          height: 28px;
          width: 28px;
        }
      }

      .searchbox-input {
        padding-left: 3.125rem !important;
      }

      &.searchbox-open {
        width: 250px !important;
      }
    }

    button {
      border: none;
      background: transparent;
      color: $color-blue;
      padding: 0;
      min-width: inherit;
      position: relative;

      .bi-x {
        opacity: 0;
        position: absolute;
      }
      &:active,
      &:focus {
        outline: none;
        background-color: transparent !important;
      }

      &.aberto {
        .bi-x {
          opacity: 1;
          position: relative;
        }

        .bi-list {
          opacity: 0;
          position: absolute;
        }
      }

      &.fechado {
        .bi-x {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
        }

        .bi-list {
          opacity: 1;
          position: relative;
        }
      }

      svg {
        height: 40px;
        width: 40px;
        transition: opacity 0.5s;
      }
    }
  }

  .navbar-collapse {
    background-color: $color-white;
    border: none;
    left: 0;
    position: absolute;
    top: 98px;
    width: 100%;
    height: calc(100vh - 120px);
    overflow-y: scroll;
  }

  .navbar-nav {
    margin: $spacer-4 0 0;
    padding: 0 0.938rem;
    display: block;

    li {
      //   margin-bottom: 1rem;

      a {
        font-weight: 500;
        font-style: normal;
        font-size: 1rem;
        color: $color-blue;
        text-decoration: none;
      }

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-menu {
        border: 0;
        padding: 0;
        li {
          margin-bottom: $spacer-2;
          a {
            font-weight: 400;
            font-size: 0.88rem;
            color: #00799e;
            word-break: break-word;
            white-space: normal;
          }
        }
      }
    }
  }

  .global-zone {
    background-color: #f0f5fa;
    padding: 0 0.938rem;

    .global-links {
      padding: 1.5rem 0 1rem;
      .global-links-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        a {
          font-weight: 500;
          font-style: normal;
          font-size: 0.75rem;
          color: #00799e;
          text-decoration: underline;
        }
      }
    }
    .global-language {
      border-top: 1px solid #c4d7ed;
      padding: 0.563rem 0;
    }
  }
}

.gs1Menu {
  margin: 0;
  margin-bottom: 0.313rem;
  box-shadow: 0px 2px 3px rgba(0, 44, 108, 0.2);

  .gs1MenuContainer {
    padding: 0;

    .gs1MenuNav {
      padding: 0;
      .navbar-collapse {
        padding: 0 0.938rem;
      }
      .navbar-nav {
        flex: 1;
        justify-content: space-between;
        padding: $spacer-1 0;
        position: relative;

        > li {
          margin-bottom: 0;

          > a {
            color: $color-blue;
            display: block;
            text-decoration: none;
            padding: $spacer-2 0.625rem;
            position: relative;
            margin-top: $spacer-1;
            &:hover {
              background: $color-ui-2;
              border-radius: 0.25rem;
            }
            &:before {
              content: '';
              height: 6px;
              display: inline-block;
              position: absolute;
              left: 0;
              bottom: -6px;
              width: 100%;
            }
          }

          &.menuSelecionado {
            background: transparent;
            > a {
              color: $color-orange-accessible;
              position: relative;

              &::after {
                content: '';
                display: block;
                background-color: $color-orange-accessible;
                border-radius: 0.125rem;
                height: 4px;
                position: absolute;
                bottom: -4px;
                left: 0;
                margin: 0;
                width: 100%;
              }
              &:before {
                bottom: -11px;
              }
            }
          }
        }
      }

      .dropdown-toggle {
        font-weight: 500;
        font-style: normal;
        font-size: 0.875rem;
        color: $color-blue;

        &:after {
          display: none;
        }
      }

      .dropdown-menu {
        padding-top: 1.25rem;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0 0 0.25rem 0.25rem;
        box-shadow: 0 0.625rem 1.875rem rgba(0, 44, 108, 0.2);
        background-color: $color-white;
        font-family: 'Gotham SSm A', 'Gotham SSm B', Verdana, sans-serif;
        font-weight: 400;
        font-style: normal;
        top: 52px;
        margin: 0; 
        a {
          text-decoration: none;

          &:hover {
            color: $color-blue !important;
            text-decoration: underline;
          }
        }

        ul a,
        .media-heading {
          color: $color-blue;
          font-size: 1rem;
          font-weight: 500;
        }

        ul ul a,
        .list-unstyled a {
          font-size: 0.875rem;
          font-weight: 400;
          color: $color-link-accessible;
        }
        &.setores,
        &.hubDados,
        &.indicesPesquisas,
        &.educacaoPratica,
        &.gs1Conteudo,
        &.sobreGS1 {
          padding: 0;
          .grid-menu {
            padding: 0;
            margin-bottom: 0;
            .continuation-content {
              padding-top: 2.75rem;
            }
            .box-gray-menu {
              padding: $spacer-3 2.25rem;
              position: relative;
              width: 100%;
              height: 100%;
              background-color: $color-ui-1;
            }
            .main-heading {
              padding-top: 1.25rem;
            }
            .list-unstyled {
              padding-bottom: 1.875rem;
            }
          }
        }
      }

      .main-heading {
        hr {
          margin: 0.5rem 0;
        }
      }

      .media {
        padding: 1rem 0 0;

        .media-heading {
          display: block;
          margin-bottom: 1.25rem;
        }

        .list-unstyled {
          li {
            line-height: 1.063rem;
            margin-bottom: 0.875rem;
          }
        }
      }

      /* Grid */
      .grid-menu {
        padding: 0 1.875rem 1.875rem;
        margin-bottom: 0;
      }

      .yamm {
        .nav,
        .collapse,
        .dropup,
        .dropdown {
          position: static;
        }

        .container {
          position: relative;
        }

        .dropdown-menu {
          left: auto;
        }

        .nav.navbar-right .dropdown-menu {
          left: auto;
          right: 0;
        }

        .yamm-content {
          padding: 1.25rem 1.875rem;
        }

        .dropdown.yamm-fw .dropdown-menu {
          left: 0;
          right: 0;

          &.servicosSolucoes {
            left: 0;
            right: 0;
          }

          &.setores {
            left: 25%;
            right: 15%;
          }

          &.educacaoPratica {
            left: 30%;
            right: 0;
          }
        }
      }
    }
  }
}

/*
Navbar "hovernav" dropdown menu - this works only for screen sizes larger than phones.
The Bootstrap CSS is unchanged. If you don't want this, delete this segment of the CSS
and delete the corresponding segment of bst.js
*/
@media (min-width: 768px) {
  .navbar-nav .open ul {
    display: none;
  }
  .navbar-nav .hovernav:hover .dropdown-menu {
    display: block;
  }
}

@media (min-width: 767px) {
  .gs1MenuNav {
    .panel-group {
      width: 400px;
    }
    .thumbnail {
      margin: 0;
    }
  }
}

@media (max-width: 992px) {
  .gs1MenuNav {
    .nav > li > a {
      margin: 0 0 0 0.625rem !important;
    }

    .dropdown-toggle {
      line-height: $line-height-default;
      font-size: 1rem;
    }
  }
}
