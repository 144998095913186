.list-social-share{
    margin-bottom: 1.75rem;
    padding: 0;
    list-style: none;
    > li{
        display: inline-block;
        margin-right: .75rem;
        &:last-child{
            margin-right: 0;
        }
        > a{
            svg{
                width: 1.5rem;
                height: 1.5rem;
                fill: $color-blue;
                &:hover{
                    fill: $color-link;
                }
            }
        }
    }
}
@media (max-width: 736px) {
    .list-social-share{
        margin-bottom: 1.75rem;
        padding: 0;
        list-style: none;
        > li{
            display: inline-block;
            margin-right: .75rem;
            &:last-child{
                margin-right: 0;
            }
            > a{
                svg{
                    width: 2.25rem;
                }
            }
        }
    }
}
.card-social{
    height: auto;
    display: table;
    .card-body{
        .title-social{
            font-size:$font-size-little-one;
            font-weight: 400;
            color: $color-blue;
        }
        .list-social-share{
            margin-bottom: 0;
        }
    }
}