.table{
    &.table-striped{
        tbody{
            tr{
                &:nth-of-type(odd){
                    background-color: $color-ui-1;
                }
            }
        }
    }
    th{
        color: $color-blue;
        border-top:none;
        border-bottom:none; 
    }
    th,td{
        padding:$spacer-2 $spacer-3;
        border-color: $color-ui-3;
        font-size:$font-size-little-one;
        vertical-align: middle;
    }
} 


ul.numeric-decimals { 
    counter-reset:section; 
    list-style-type:none; 
    text-align: justify !important;
    margin-bottom: 20px;
}
ul.numeric-decimals li { list-style-type:none; display: block;
    }
ul.numeric-decimals li ul { counter-reset:subsection; }
ul.numeric-decimals li:before{
    counter-increment:section;
    content:counter(section) ". ";/*content:"Section " counter(section) ". ";*/
}
ul.numeric-decimals li ul li:before {
    counter-increment:subsection;
    content:counter(section) "." counter(subsection) " ";
}
