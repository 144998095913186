.footer {
  background: white;
  flex-shrink: 0;
  padding: 1rem 0;
  .child {
    max-width: 1120px;
    margin: 0 auto;
    height: 100%;
    // padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 3rem;
    padding: 0 2rem;

    .main_footer {
      display: flex;
      flex-direction: column;
      justify-content: center;

      nav {
        ul {
          list-style: none;
          display: flex;
          flex-direction: row;
          column-gap: 1rem;
          li {
            .lia {
              font-size: 0.75rem;
              color: #008dbd;
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
      }
      p {
        font-size: 0.75rem;
        color: #b1b3b3;
      }
    }
    .social_media {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      align-items: center;

      div {
        border-radius: 50%;
      }
    }
    .contact {
      display: flex;
      flex-direction: column;
      row-gap: 0.34124rem;
      span {
        font-size: 1rem;
        color: #002c6c;
      }
      a {
        font-size: 0.75rem;
        color: #008dbd;
      }
      p {
        font-size: 0.75rem;

        span {
          font-size: 0.75rem;
          color: #008dbd;
        }
      }
    }
  }
}




.modal {
  overlay: {
    position: "fixed";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: "#262626CC";
  }

  div_block {
    position: "relative";
    display: "flex";
    flex-direction: "column";
    row-gap: "1.9rem";
  }

  content: {
    width: 70vw;
    height: 89vh;
    marginTop: 0;
    marginBottom: 0;
    marginLeft: auto;
    marginRight: auto;
    border: 1px solid #ccc;
    back: #fff;
    overflow: auto;
    WebkitOverflowScrolling: touch;
    borderRadius: 4px;
    outline: none;
    padding: 20px;
  }

  p.p{
    text-align: justify !important;
    margin-bottom: 20px;
    line-height: 22px;
    font-size:15px;
  }
  
  ul.ul{
    margin-left: 40px;
    text-align: justify !important;
    margin-bottom: 20px;
    line-height: 22px;
    font-size:15px;
  }
  
  ul.ul li{
    margin-bottom: 10px;
    list-style-type: lower-alpha !important;
    padding-left: 1em;
  }
  
  .listtype
  {
    list-style-type: lower-alpha !important;
    padding-left: 1em !important;
  }
  
  
  .divlist{
    margin-left: 30px;
    width: 95%;
    display: table;
    margin-bottom: 20px;
  }
  
  .spanlist{
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
  }
  
  ul.nestedcounterlist {
    counter-reset: item;
    text-align: justify !important;
    margin-bottom: 20px;
    line-height: 22px;
    font-size:15px;
  }
  
  ul.nestedcounterlist li {
    display: block;
    padding-left: 1em;
  }
  
  ul.nestedcounterlist li::before {
    counter-increment: item;
  }
  
  ul.nestedcounterlist ol {
    counter-reset: item;
  }
  
  ul.nestedcounterlist ol li{
    margin-bottom: 20px;
    padding-left: 1em;
  }
  
  ul.nestedcounterlist ol li ul li{
    margin-bottom: 20px;
    list-style-type: lower-alpha !important;
    padding-left: 1em !important;
  }

    
  .footer{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
  }
  

}