.modal {
  .modal-content {
    .modal-header {
      padding:$spacer-4 0 0 $spacer-4;
      margin-right:2.5rem;
      border-bottom: none;
      position: relative;
      display: table;
      .close {
        min-width: auto;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0.625rem;
        right: -1.625rem;
        font-weight: 400;
        color: $color-blue;
        opacity: 1;
        &:hover {
          background-color: transparent;
        }
      }
      .modal-title {
        margin-bottom: 0;
        margin-top: 0;
        font-family: 'Gotham SSm A', 'Gotham SSm B', Verdana, sans-serif;
        font-size: 1.375rem;
        font-weight: 400;
        color: $color-blue;
        line-height: $line-height-tight;
        text-align: left;
      }
    }
    .modal-body {
      padding:0 2.5rem $spacer-4 $spacer-4;
      margin-top:$spacer-2;
      line-height: 1.38rem;
      color: $color-ui-8;
      label {
        font-size: 0.938rem;
        margin-left: 0.5rem;
        margin-bottom: 0;
      }
      .form-label {
        margin-left: 0;
      }
      .boxNewsletter {
        .cadastrar {
          float: right;
        }
      }
    }
    .modal-footer {
      padding:$spacer-4;
      border-top: none;
      .btn {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 0.563rem 1rem;
        line-height: 1.25rem;
      }
      .btn-default {
        border: 1px solid $color-blue;
        color: $color-blue;
        margin-right: 1rem;
        &:hover {
          background-color: $color-blue-dark-1;
          border-color: $color-blue-dark-1;
          color: $color-white;
        }
        &:active {
          background-color: $color-blue-dark-2;
          border-color: $color-blue-dark-2;
          text-decoration: none;
          color: $color-white;
        }
        &:focus {
          background-color: $color-blue;
          border-color: $color-blue;
          box-shadow: none;
          text-decoration: none;
          color: $color-white;
        }
      }
    }
  }
  &.modalNewsletter {
    .modal-content {
      .modal-body {
        padding:0 $spacer-4 $spacer-4 $spacer-4;
      }
    }
  }
  &#fale-conosco {
    .form-group {
      [class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .mensagem {
    margin-bottom: 1rem;
  }
}
