.custom-col12{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.custom-display-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
@media (max-width: 640px) {
    .custom-col12{
        padding-right: 0px;
        padding-left: 0px;
    }
    .custom-fontSize{
        font-size: 14px;
    }
    .custom-display-buttons{
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 380px){
    .custom-fontSize{
        padding: 12px;
    }
}
@media (max-width: 350px){
    .custom-fontSize{
        padding: 8px;
    }
}