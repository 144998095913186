/*! GS1 Web Guidelines */
// ============================================================================
@use "variables" as gs1-vars;
@use "../node_modules/rfs/scss" as rfs with (
  $rfs-base-value: gs1-vars.$rfs-base-value,
  $rfs-factor: gs1-vars.$rfs-factor,
  $rfs-breakpoint: gs1-vars.$rfs-breakpoint
);

@import "_variables.scss";
@import "_colors.scss";
@import "_accordion.scss";
@import "_button.scss";
//@import "_buttons.scss";
//@import "_display.scss";

@import "_alert.scss";
@import "_background.scss";
@import "_badge.scss";
@import "_banner.scss";
//@import "_borders.scss";
@import "_breadcrumb.scss";
@import "_calendar.scss";
@import "_cards.scss";
@import "_carousel.scss";
@import "_dropdown.scss";
@import "_footer.scss";
@import "_forms.scss";
@import "_grid-framework.scss";
//@import "_grid.scss";
@import "_header.scss";
@import "_indicador-senha.scss";
@import "_library-display.scss";
@import "_list-group.scss";
@import "main.min.css";
@import "_menu.scss";
@import "_modal.scss";
//@import "_nav.scss";
//@import "_navbar.scss";
@import "_normalize-custom.scss";
@import "_pagination.scss";
@import "_progress-bar.scss";
@import "_progress-stepper.scss";
//@import "_reboot.scss";
//@import "_root.scss";
@import "_scss.scss";
@import "_sharepoint.scss";
@import "_social-share.scss";
@import "_spacer.scss";
@import "_statistic-tile.scss";
@import "_structured-list.scss";
@import "_styles.scss";
@import "_table.scss";
@import "_tabs.scss";
@import "_tooltip.scss";
@import "_transition.scss";
@import "_type.scss";
@import "_utilities.scss";
@import "_webparts.scss";
@import "_yamm.scss";
@import "_custom.scss";
