.badge{
   &.no-bg-padding{
      padding:0;
      font-weight: 500;
   }
   padding: 0.375rem 0.625rem; 
   font-size: 0.75rem;
   font-weight: 500;
   line-height: 1.3;
   border-radius: .25rem;
   color: $color-white;
   margin-bottom: $spacer-1;
}