.custom-button{
    margin-right: 36px;
}

@media (max-width: 640px) {
    .custom-button{
        margin-right: 0px;
        width: 100%;
    }
    .custom-button-size{
        width: 100%;
    }
    .custom-display{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}