// Type
// ============================================================================

@import "colors";
@import "variables";
@import "node_modules/rfs/scss";


%heading {
  font-weight: 300;
  line-height: $line-height-heading;
  color: $color-blue;
}

:root {
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
  font-variant-numeric: tabular-nums; // For tabular 1
  line-height: $line-height-default;
  color: $color-ui-8;
}

html {
  font-size: $font-root; // default font-size, typically 16px
}

body {
  @include font-size($font-body);
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
  color: $color-ui-8;
}

.gs1-eyebrow {
  display: block;
  color: $color-orange-accessible;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: $spacer-1;
}

h1,
h2,
h3, 
h4,
h5,
h6 {
  margin-top: 0;
  font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
}

h1,
.gs1-h1 {
  @extend %heading;
  font-size:$font-1;
  font-weight: 200;
  margin-bottom: $spacer-1;

  > .gs1-eyebrow {
    @include font-size(1.25rem);
    font-weight: 400;
  }
}

h2,
.gs1-h2 {
  @extend %heading;
  @include font-size($font-2);
  margin-top: 1.5em;
  margin-bottom:$spacer-4;

  > .gs1-eyebrow {
    @include font-size(1.125rem);
  }
}

h3,
.gs1-h3 {
  @extend %heading;
  @include font-size($font-3);
  margin-top: 1.5em;
  margin-bottom:$spacer-3;

  > .gs1-eyebrow {
    font-size:$font-size-little;
  }
}
.ms-rtestate-field h4, h4.ms-rteElement-H4, 
h4,
.gs1-h4 {
  @extend %heading;
  font-size:$font-4;
  margin-top: 1.5em;
  margin-bottom:$spacer-3;
}

h5,
.gs1-h5 {
  @extend %heading;
  font-size:$font-5;
  margin-top: 1.5em;
  @include margin-bottom($spacer-2);
  font-weight: 600;
}

h6,
.gs1-h6 {
  @extend %heading;
  @include font-size($font-6);
  margin-top: 1.5em;
  @include margin-bottom($spacer-2);
}

.gs1-display-1 {
  @include font-size($font-display-1);
  font-weight: 200;
  letter-spacing: -0.03em;
  margin-bottom: $spacer-1;
}

.gs1-display-2 {
  @include font-size($font-display-2);
  font-weight: 200;
  letter-spacing: -0.03em;
  margin-bottom: $spacer-1;
}

.gs1-subtitle {
  @include font-size($font-size-subtitle);
  line-height: $line-height-tight;
  @include margin-bottom($spacer-5);
}

.gs1-intro {
  @include font-size($font-size-intro);
  margin-top: 1em;
  margin-bottom:$spacer-4;
}

.gs1-big {
  @include font-size($font-size-big);
}

.gs1-little,
figcaption {
  font-size:$font-size-little;
}

.gs1-legal {
  @include font-size($font-size-tiny);
  color: $color-ui-6;
}

.gs1-line-height-tight {
  line-height: $line-height-tight;
}

.ms-rtestate-field p, 
p.ms-rteElement-P,
address,
aside,
details,
dialog,
p,
pre,
blockquote {
  margin-top: 0;
  margin-bottom: 1em;
}

a {
  color: $color-link-accessible;
  transition: color 0.1s ease;

  &:hover {
    color: $color-blue;
  }

  &:active {
    color: $color-blue-dark-2;
  }

  &:focus {
    outline-color: $color-blue;
  }
}

ol,
ul {
  padding-left: 1.25em;
}

li {
  @include margin-bottom($spacer-2);
}
