.notification {
    width: 100%;
    height: 90px;
    border-left: 3px solid rgba(0, 128, 55, 1);
    padding: 21px 24px;
    display: flex;
    flex-direction: row;
    background-color: rgba(204, 241, 220, 1);
    align-items: center;
    border-radius: 4px;
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.04);

    .pic{
      display: flex;
      margin:0px 20px 0px 0px;
    }

    .content{
      display:flex;
      flex-direction: column;
    }

    span {
      line-height: 1.5;
      &.title {
        font-size: 1.125rem;
        font-weight: 600;
        color: rgba(2, 107, 48, 1)
      }
      &.text {
        font-size: 0.9375rem;
        color: rgba(38, 38, 38, 1)
      }
    }
  }

  .notificationerror {
    width: 100%;
    height: 90px;
    border-left: 3px solid rgba(186, 27, 35, 1);
    padding: 15px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background: rgba(242, 99, 52, 0.15) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.04);

    .content{
      display:flex;
      flex-direction: column;
    }

    span {
      line-height: 1.5;
      &.title {
        font-size: 1.125rem;
        font-weight: 600;
        color: rgba(186, 27, 35, 1);
      }
      &.text {
        font-size: 0.9rem;
        color: rgba(69, 69, 69, 1);
      }
    }
  }

  h5{
    font-weight: 200 !important;
  }