/*--------------------------------------------------------------
# Ribbon Fix
--------------------------------------------------------------*/
.ms-webpart-chrome-title *,
.ms-webpart-chrome-title *:before,
.ms-webpart-chrome-title *:after,
#s4-ribbonrow *,
#s4-ribbonrow *:before,
#s4-ribbonrow *:after,
.ms-webpart-controlBox,
.ms-dialog *,
.ms-dialog *:before,
.ms-dialog *:after,
#idStorefrontLayoutRoot *,
#idStorefrontLayoutRoot *:before,
#idStorefrontLayoutRoot *:after,
.ms-dialog #s4-workspace *,
.ms-dialog #s4-workspace *:before,
.ms-dialog #s4-workspace *:after,
.ms-dlgTitle *,
.ms-dlgTitle *:before,
.ms-dlgTitle *:after,
.ms-srch-result-groups *,
.ms-srch-result-groups *:before,
.ms-srch-result-groups *:after,
#Paging *,
#Paging *:before,
#Paging *:after,
#Hero-WPQ2 *,
#Hero-WPQ2 *:before,
#Hero-WPQ2 *:after,
#suiteBarButtons *,
#suiteBarButtons *:before,
#suiteBarButtons *:after {
    -webkit-box-sizing: initial !important;
    -moz-box-sizing: initial !important;
    box-sizing: content-box !important;
}

/*--------------------------------------------------------------
# Clean Sharepoint Styles
--------------------------------------------------------------*/
#s4-workspace{
	height: 100vh;
}

#s4-bodyContainer {
	padding: 0;
}

#titleAreaBox {
	margin: 0;
}

#contentRow {
	padding: 0;
}

#sideNavBox {
	display: none;
}

#contentBox {
	margin: 0;
	min-width: 100%;
}
.ms-rtestate-field h1, h1.ms-rteElement-H1, .ms-rtestate-field h2, h2.ms-rteElement-H2 {
    color: $color-blue;
}
.ms-webpartzone-cell {
    margin: auto auto 0;
}
.ms-webpart-zone,
.ms-webpart-cell-vertical,
.ms-webpart-chrome-vertical
{
	display: block;
}
.imgDestaque{
	.ms-rtestate-field{
		width: 100%;
	}
}
.ms-core-form-bottomButtonBox{
	input[type=button]{
		margin-right: .938rem;
		border-radius: .25rem;
	}
}
h2.ms-webpart-titleText{
	&.ms-webpart-titleText{
		font-family: "Gotham SSm A", "Gotham SSm B", Verdana, sans-serif;
		@extend %heading;
		@include font-size($font-2);
		margin-top: 1.5em;
		@include margin-bottom($spacer-4);
		color:$color-blue;
		text-overflow: initial;
		text-align: initial !important;
		nobr{
			white-space: normal;
		}
	}
}
/*--------------------------------------------------------------
# Custom Sharepoint
--------------------------------------------------------------*/
#suiteBar {
	li {
		margin: auto;
	}
}

@media (max-width: 767px) {
	
	#s4-workspace{
		overflow-x: hidden;
	}
	#s4-titlerow {
		height: 82px !important;
	}

	#titleAreaBox {
		padding: 10px 0 0 0 !important;
	}

	#titleAreaRow {
		position: relative !important;
		margin: 0;
	}

	.ms-siteicon-img {
		width: 85px !important;
	}

	#contentRow {
		padding-top: 38px!important;
	}
}

input[type=button], 
input[type=reset], 
input[type=submit], 
button{
	margin-left: 0;
}
.wp-height,
.wp-home{
	.ms-WPBody.noindex,
	.ms-rtestate-field,
	.ms-webpart-chrome-vertical,
	.ms-fullWidth{
		height: 100%;
	}
}

.ms-srch-sbprogressLarge {
	background: url("../public/spinner.svg") no-repeat 5px 3px;
	background-size: 24px;
}

a {
	&:visited{
		color: $color-link-accessible;
	}
}

p{
	a {
		color: $color-link-accessible;
		transition: color 0.1s ease;
	
		&:hover {
		  	color: $color-blue;
		}
	  
		&:active {
		  	color: $color-blue-dark-2;
		}
	  
		&:focus {
			color: $color-link-accessible;
		  	outline-color: $color-blue;
		}
		&:visited{
		  	color: $color-link-accessible;
		}
	  }
}
.color-white{
	color: $color-white;
}
.ms-rte-embedil {
	display: block !important;
}

//Remove borda editor de conteúdo
.ms-WPBorder {
    border: none;
}